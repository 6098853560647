export const AMENITY_OPTIONS = [
  {
    label: 'Fitness Center',
    value: 'fitnessCenter',
  },
  {
    label: 'Laundry Room',
    value: 'laundryRoom',
  },
  {
    label: 'Washer/Dryer in Units',
    value: 'washerAndDryerInUnits',
  },
  {
    label: 'Bike Room',
    value: 'bikeRoom',
  },
  {
    label: 'Doorman/Concierge',
    value: 'doorman',
  },
  {
    label: 'Storage Units',
    value: 'storageUnits',
  },
  {
    label: 'Covered Parking',
    value: 'coveredParking',
  },
  {
    label: 'Uncovered Parking',
    value: 'uncoveredParking',
  },
  {
    label: 'Pool',
    value: 'pool',
  },
  {
    label: 'Shared Recreation Room',
    value: 'sharedRecreationRoom',
  },
  {
    label: 'Playground',
    value: 'playground',
  },
  {
    label: 'Tennis Courts',
    value: 'tennisCourts',
  },
  {
    label: 'Shared Garden',
    value: 'sharedGarden',
  },
  {
    label: 'Shared Roof Deck',
    value: 'sharedRoofDeck',
  },
  {
    label: 'Shared Common Outdoor Space',
    value: 'sharedCommonOutdoorSpace',
  },
  {
    label: 'On Site Superintendent',
    value: 'onSiteSuperintendent',
  },
  {
    label: 'On Site Building Management',
    value: 'onSiteBuildingManagement',
  },
  {
    label: 'Common Lounge Space',
    value: 'commonLoungeSpace',
  },
]

export const RentTypes = {
  MARKET_RATE: 'Market Rate',
  RENT_STABILIZED: 'Rent Stabilized',
  RENT_CONTROLLED: 'Rent Controlled',
  MONTH_TO_MONTH: 'Month to Month',
  SECTION_8: 'Section 8',
  VACANT: 'Vacant',
  EMPLOYEE: 'Employee',
}

export const RENT_TYPES = [
  RentTypes.MARKET_RATE,
  RentTypes.RENT_STABILIZED,
  RentTypes.RENT_CONTROLLED,
  RentTypes.SECTION_8,
  RentTypes.VACANT,
  RentTypes.EMPLOYEE,
]

export const RentTypesList = [
  { label: RentTypes.MARKET_RATE, value: RentTypes.MARKET_RATE },
  { label: RentTypes.RENT_STABILIZED, value: RentTypes.RENT_STABILIZED },
  { label: RentTypes.RENT_CONTROLLED, value: RentTypes.RENT_CONTROLLED },
  { label: RentTypes.SECTION_8, value: RentTypes.SECTION_8 },
  { label: RentTypes.MONTH_TO_MONTH, value: RentTypes.MONTH_TO_MONTH },
  { label: RentTypes.VACANT, value: RentTypes.VACANT },
  { label: RentTypes.EMPLOYEE, value: RentTypes.EMPLOYEE },
]
