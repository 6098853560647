import React from 'react'
import { findIndex } from 'lodash'
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, withStyles } from '@material-ui/core'
import { Grid, Paper, Typography, Button } from '@mui/material'

import ExpandMore from '@mui/icons-material/ExpandMore'

import {
  containsAdjustmentName,
  getCustomRowNames,
  getMissingCustomAdjustmentDiscussions,
  hasAdjustments,
} from 'shared/helpers/salesApproach/salesCompAdjustments'
import { SALES_APPROACH_TYPES } from 'shared/constants/salesApproach'
import unitOfComparisonDiscussionModule from 'shared/utils/textGeneration/salesApproach/unitOfComparisonDiscussion'
import marketConditionsDiscussionModule from 'shared/utils/textGeneration/salesApproach/marketConditionsDiscussion'
import utilityDiscussion from 'shared/utils/textGeneration/salesApproach/utilityDiscussion'
import locationDiscussion from 'shared/utils/textGeneration/salesApproach/locationDiscussion'
import sizeAdjustmentDiscussion from 'shared/utils/textGeneration/salesApproach/sizeAdjustmentDiscussion'
import incomeOrRentStabilizationLevelDiscussion from 'shared/utils/textGeneration/salesApproach/incomeAndRentStabilizationLevelDiscussion'
import conditionDiscussion from 'shared/utils/textGeneration/salesApproach/conditionDiscussion'
import commercialSpaceDiscussion from 'shared/utils/textGeneration/salesApproach/commercialSpaceDiscussion'
import averageUnitSizeDiscussion from 'shared/utils/textGeneration/salesApproach/averageUnitSizeDiscussion'
import conditionsOfSaleDiscussion from 'shared/utils/textGeneration/salesApproach/conditionsOfSaleDiscussion'
import financingTermsDiscussion from 'shared/utils/textGeneration/salesApproach/financingTermsDiscussion'
import propertyRightsDiscussion from 'shared/utils/textGeneration/salesApproach/propertyRightsDiscussion'
import siteAreaDiscussion from 'shared/utils/textGeneration/salesApproach/siteAreaDiscussion'
import siteUtilityDiscussion from 'shared/utils/textGeneration/salesApproach/siteUtilityDiscussion'
import zoningDiscussion from 'shared/utils/textGeneration/salesApproach/zoningDiscussion'
import approvalsDiscussion from 'shared/utils/textGeneration/salesApproach/approvalsDiscussion'
import densityDiscussion from 'shared/utils/textGeneration/salesApproach/densityDiscussion'

import { ADJUSTMENT_GROUPS } from 'shared/helpers/salesApproach/salesCompAdjustmentConstants'
import { IncomeAdjustmentKeys } from 'shared/constants/report/sales/salesAdjustment'
import { PropertyTypes } from 'shared/constants'

import NarrativeComponent from 'client-shared/components/NarrativeComponent'

import { GeneratedComment } from '../../../../shared/components'

import { getOtherGeneratedText } from './salesAdjustmentGeneratedTexts'

import styles from './styles'

class SalesAdjustmentDiscussions extends React.PureComponent {
  state = {
    expansionPanels: {
      unitComparison: false,
      market: false,
      location: false,
      utility: false,
      other: false,
    },
    showAll: false,
    buttonName: 'Show All',
  }

  componentDidMount = () => {
    const { batch, change, formValues } = this.props
    const { compAdjustments, otherAdjustmentDiscussions } = formValues

    batch(() => {
      const allCustomAdjustmentDiscussions = getMissingCustomAdjustmentDiscussions(
        otherAdjustmentDiscussions,
        compAdjustments
      )
      if (allCustomAdjustmentDiscussions.length) {
        change('otherAdjustmentDiscussions', allCustomAdjustmentDiscussions)
      }
    })
  }

  togglePanel = name => (event, expanded) => {
    this.setState(state => ({
      ...state,
      expansionPanels: {
        ...state.expansionPanels,
        [name]: expanded,
      },
    }))
  }

  toggleShowAll = () => {
    const buttonName = this.state.showAll ? 'Show All' : 'Hide All'
    const expanded = !this.state.showAll
    this.setState(state => ({
      expansionPanels: {
        unitComparison: expanded,
        market: expanded,
        location: expanded,
        utility: expanded,
        other: expanded,
      },
      showAll: expanded,
      buttonName,
    }))
  }

  getOtherDiscussionDataPath = name => {
    const {
      formValues: { otherAdjustmentDiscussions = [] },
    } = this.props
    const index = findIndex(otherAdjustmentDiscussions, discussion => discussion?.name === name)
    if (index === -1) {
      return null
    }
    return `otherAdjustmentDiscussions[${index}].discussion`
  }

  render() {
    const { classes, formValues } = this.props
    const { expansionPanels, buttonName } = this.state
    const incomeLevel = formValues.incomeAdjustmentLevel === IncomeAdjustmentKeys.income
    const stabilizationLevel = formValues.incomeAdjustmentLevel === IncomeAdjustmentKeys.rentStabilization
    const incomeLevelDiscussionName = incomeLevel ? 'Income Level Discussion' : 'Rent Stabilization Level Discussion'
    const { type, compAdjustments } = formValues
    const isImprovedSales = type === SALES_APPROACH_TYPES.IMPROVED

    const hasCommercialSpaceAdjustment = containsAdjustmentName(compAdjustments, 'commercial')
    const hasAverageUnitSizeAdjustment = containsAdjustmentName(compAdjustments, 'averageUnitSize')

    const customUtilitiesAdjustments = (compAdjustments?.[0]?.adjustments || []).filter(
      ({ custom, groupName }) => custom && groupName === ADJUSTMENT_GROUPS.UTILITY
    )

    return (
      <Grid
        sx={{ paddingTop: '16px' }}
        container
        wrap="nowrap"
        direction="column"
        className={classes.discussionContainer}
      >
        <Grid item xs={12}>
          <Paper className={classes.discussions_Container}>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h6">Discussions</Typography>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.discussions_Toggle_Btn}>
                  <Button onClick={this.toggleShowAll}>{buttonName}</Button>
                </div>
              </Grid>
            </Grid>
          </Paper>

          <ExpansionPanel
            square={true}
            className={classes.discussion}
            expanded={expansionPanels.unitComparison}
            onChange={this.togglePanel('unitComparison')}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
              <Typography variant="subtitle2">Unit of Comparison</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} className={classes.discussion}>
                  <NarrativeComponent
                    title="Unit of Comparison Discussion"
                    tooltipText="The following generated text will appear in the Sales Comparison Approach of your report."
                    name="unitOfComparisonDiscussion"
                    generatedText={unitOfComparisonDiscussionModule.generate}
                    data={unitOfComparisonDiscussionModule.mapDTO(formValues)}
                  />
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            square={true}
            className={classes.discussion}
            expanded={expansionPanels.market}
            onChange={this.togglePanel('market')}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
              <Typography variant="subtitle2">Market</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container direction="row">
                <Grid item xs={12} className={classes.discussion}>
                  <NarrativeComponent
                    title="Property Rights Discussion"
                    name="propertyRightsDiscussion"
                    tooltipText="The following generated text will appear in the Sales Comparison Approach of your report."
                    generatedText={propertyRightsDiscussion.generate}
                    data={propertyRightsDiscussion.mapDTO(formValues)}
                  />
                </Grid>
                <Grid item xs={12} className={classes.discussion}>
                  <NarrativeComponent
                    title="Financing Terms Discussion"
                    name="financingTermsDiscussion"
                    tooltipText="The following generated text will appear in the Sales Comparison Approach of your report."
                    generatedText={financingTermsDiscussion.generate}
                    data={financingTermsDiscussion.mapDTO(formValues)}
                  />
                </Grid>
                <Grid item xs={12} className={classes.discussion}>
                  <NarrativeComponent
                    title="Conditions of Sale Discussion"
                    name="conditionsOfSaleDiscussion"
                    tooltipText="The following generated text will appear in the Sales Comparison Approach of your report."
                    generatedText={conditionsOfSaleDiscussion.generate}
                    data={conditionsOfSaleDiscussion.mapDTO(formValues)}
                  />
                </Grid>
                <Grid item xs={12} className={classes.discussion}>
                  <NarrativeComponent
                    title="Market Conditions (Time) Discussion"
                    tooltipText="The following generated text will appear in the Sales Comparison Approach of your report."
                    name="marketConditionsDiscussion"
                    generatedText={marketConditionsDiscussionModule.generate}
                    data={marketConditionsDiscussionModule.mapDTO(formValues)}
                  />
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            square={true}
            className={classes.discussion}
            expanded={expansionPanels.location}
            onChange={this.togglePanel('location')}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
              <Typography variant="subtitle2">Location</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} className={classes.discussion}>
                  <NarrativeComponent
                    title="Location Discussion"
                    tooltipText="The following generated text will appear in the Sales Comparison Approach of your report."
                    name="locationDiscussion"
                    generatedText={locationDiscussion.generate}
                    data={locationDiscussion.mapDTO(formValues)}
                  />
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          {isImprovedSales && (
            <ExpansionPanel
              square={true}
              className={classes.discussion}
              expanded={expansionPanels.utility}
              onChange={this.togglePanel('utility')}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <Typography variant="subtitle2">Utility</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container direction="row" spacing={16}>
                  <Grid item xs={12} className={classes.discussion}>
                    <NarrativeComponent
                      title="Utility Discussion"
                      tooltipText="The following generated text will appear in the Sales Comparison Approach of your report."
                      name="utilityDiscussion"
                      generatedText={utilityDiscussion.generate}
                      data={utilityDiscussion.mapDTO(formValues)}
                      regenerateOnChange={[
                        'otherUtilityAdjustmentData.length',
                        ...customUtilitiesAdjustments.map(
                          (_, index) => `otherUtilityAdjustmentData[${index}].shouldGenerateWriteup`
                        ),
                      ]}
                    />
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )}
          <ExpansionPanel
            expanded={expansionPanels.other}
            onChange={this.togglePanel('other')}
            className={classes.discussion}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
              <Typography variant="subtitle2">Other</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container direction="row">
                <Grid item xs={12}>
                  <NarrativeComponent
                    title="Size Adjustment Discussion"
                    tooltipText="The following generated text will appear in the Sales Comparison Approach of your report."
                    name="sizeAdjustmentDiscussion"
                    generatedText={sizeAdjustmentDiscussion.generate}
                    data={sizeAdjustmentDiscussion.mapDTO(formValues)}
                  />
                </Grid>
                {formValues.propertyType !== PropertyTypes.COMMERCIAL &&
                  isImprovedSales &&
                  (incomeLevel || stabilizationLevel) && (
                    <Grid item xs={12}>
                      <NarrativeComponent
                        title={incomeLevelDiscussionName}
                        name="incomeLevelDiscussion"
                        tooltipText="The following generated text will appear in the Sales Comparison Approach of your report."
                        generatedText={incomeOrRentStabilizationLevelDiscussion.generate}
                        data={incomeOrRentStabilizationLevelDiscussion.mapDTO(formValues)}
                      />
                    </Grid>
                  )}
                {isImprovedSales && (
                  <>
                    <Grid item xs={12}>
                      <NarrativeComponent
                        title="Condition Discussion"
                        tooltipText="The following generated text will appear in the Sales Comparison Approach of your report."
                        name="conditionDiscussion"
                        generatedText={conditionDiscussion.generate}
                        data={conditionDiscussion.mapDTO(formValues)}
                      />
                    </Grid>
                    {hasCommercialSpaceAdjustment && (
                      <Grid item xs={12}>
                        <NarrativeComponent
                          title="Commercial Space Discussion"
                          tooltipText="The following generated text will appear in the Sales Comparison Approach of your report."
                          name="commercialSpaceDiscussion"
                          generatedText={commercialSpaceDiscussion.generate}
                          data={commercialSpaceDiscussion.mapDTO(formValues)}
                        />
                      </Grid>
                    )}
                    {hasAverageUnitSizeAdjustment && (
                      <Grid item xs={12}>
                        <NarrativeComponent
                          title="Average Unit Size Discussion"
                          tooltipText="The following generated text will appear in the Sales Comparison Approach of your report."
                          name="averageUnitSizeDiscussion"
                          generatedText={averageUnitSizeDiscussion.generate}
                          data={averageUnitSizeDiscussion.mapDTO(formValues)}
                        />
                      </Grid>
                    )}
                  </>
                )}
                {!isImprovedSales && (
                  <>
                    <Grid item xs={12}>
                      <NarrativeComponent
                        title="Site Area Discussion"
                        tooltipText="The following generated text will appear in the Sales Comparison Approach of your report."
                        name="siteAreaDiscussion"
                        generatedText={siteAreaDiscussion.generate}
                        data={siteAreaDiscussion.mapDTO(formValues)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NarrativeComponent
                        title="Site Utility Discussion"
                        tooltipText="The following generated text will appear in the Sales Comparison Approach of your report."
                        name="siteUtilityDiscussion"
                        generatedText={siteUtilityDiscussion.generate}
                        data={siteUtilityDiscussion.mapDTO(formValues)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NarrativeComponent
                        title="Zoning Discussion"
                        tooltipText="The following generated text will appear in the Sales Comparison Approach of your report."
                        name="zoningDiscussion"
                        generatedText={zoningDiscussion.generate}
                        data={zoningDiscussion.mapDTO(formValues)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NarrativeComponent
                        title="Approvals Discussion"
                        tooltipText="The following generated text will appear in the Sales Comparison Approach of your report."
                        name="approvalsDiscussion"
                        generatedText={approvalsDiscussion.generate}
                        data={approvalsDiscussion.mapDTO(formValues)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NarrativeComponent
                        title="Density Discussion"
                        tooltipText="The following generated text will appear in the Sales Comparison Approach of your report."
                        name="densityDiscussion"
                        generatedText={densityDiscussion.generate}
                        data={densityDiscussion.mapDTO(formValues)}
                      />
                    </Grid>
                  </>
                )}

                {getCustomRowNames(formValues.compAdjustments, ADJUSTMENT_GROUPS.OTHER).map((value, index) => {
                  const hasOtherAdjustments = hasAdjustments(formValues.compAdjustments, value, ADJUSTMENT_GROUPS.OTHER)
                  const dataPath = this.getOtherDiscussionDataPath(value)
                  return (
                    hasOtherAdjustments &&
                    dataPath && (
                      <Grid item xs={12} key={value}>
                        <GeneratedComment
                          title="Generated commentary"
                          label={`${value} Discussion`}
                          tooltipText="The following generated text will 
                          appear in the Sales Comparison Approach of your report."
                          getGeneratedText={() => getOtherGeneratedText(formValues, value)}
                          dataPath={dataPath}
                          isDynamicContent
                        />
                      </Grid>
                    )
                  )
                })}
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(SalesAdjustmentDiscussions)
