import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { TableRow } from '@material-ui/core'

import { Labels } from '../CommercialRentReconciliationConstants'
import { Cell, TotalCell, BaseUnitCell, TotalBaseUnitCell, SubjectUnitCell, HeadingCell } from '../TableCells'

import { getRentPsfLabel } from '../../../../../../../../shared/helpers/incomeApproach/commercial'
import { formatCurrencyFloat, formatPercentageString } from '../../../../../../shared/utils/numberFormatters'

export default class TotalsAndConclusion extends React.PureComponent {
  static propTypes = {
    units: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    rentComps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    averageAdjustmentRentPSF: PropTypes.number,
    rentRollRentBasis: PropTypes.string.isRequired,
  }

  get adjustedRentPsfLabel() {
    const { rentRollRentBasis } = this.props
    return getRentPsfLabel(rentRollRentBasis, Labels.ADJUSTED_RENT_PER_SF_PER_MONTH, Labels.ADJUSTED_RENT_PER_SF)
  }

  get baseUnitImpliedRentPsfLabel() {
    const { rentRollRentBasis } = this.props
    return getRentPsfLabel(
      rentRollRentBasis,
      Labels.BASE_UNIT_IMPLIED_PER_SF_PER_MONTH,
      Labels.BASE_UNIT_IMPLIED_PER_SF
    )
  }

  render() {
    const { units, rentComps, averageAdjustmentRentPSF } = this.props

    return (
      <React.Fragment>
        <TableRow>
          <HeadingCell>{Labels.TOTALS_AND_CONCLUSION}</HeadingCell>
        </TableRow>
        <TableRow data-qa="total-adjustments-row">
          <TotalCell data-qa="row-label-cell">{Labels.TOTAL_ADJUSTMENT}</TotalCell>
          <BaseUnitCell data-qa="base-unit-cell" />
          {units.map((unit, index) => (
            <SubjectUnitCell data-qa={`subject-unit-${index}-cell`} key={unit._id}>{`${formatPercentageString(
              get(unit, 'totalAdjustment')
            )}`}</SubjectUnitCell>
          ))}
          {rentComps.map((rentComp, index) => (
            <Cell data-qa={`rent-comp-${index}-cell`} key={rentComp._id}>{`${formatPercentageString(
              get(rentComp, 'totalAdjustment')
            )}`}</Cell>
          ))}
        </TableRow>
        <TableRow data-qa="adjusted-rent-per-sf-row">
          <Cell data-qa="row-label-cell">{this.adjustedRentPsfLabel}</Cell>
          <BaseUnitCell data-qa="base-unit-cell" />
          {units.map((unit, index) => (
            <SubjectUnitCell data-qa={`subject-unit-${index}-cell`} key={unit._id}>
              {formatCurrencyFloat(get(unit, 'adjustedRentPerSF'))}
            </SubjectUnitCell>
          ))}
          {rentComps.map((rentComp, index) => (
            <Cell data-qa={`rent-comp-${index}-cell`} key={rentComp._id}>
              {formatCurrencyFloat(get(rentComp, 'adjustedRentPerSF'))}
            </Cell>
          ))}
        </TableRow>
        <TableRow data-qa="base-unit-implied-per-sf-row">
          <Cell data-qa="row-label-cell">{this.baseUnitImpliedRentPsfLabel}</Cell>
          <TotalBaseUnitCell data-qa="base-unit-cell">
            {formatCurrencyFloat(averageAdjustmentRentPSF)}
          </TotalBaseUnitCell>
          <Cell colSpan={units.length + rentComps.length} />
        </TableRow>
      </React.Fragment>
    )
  }
}
