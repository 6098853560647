import { GeneratedText, gtConditional, gtIfElse, gtLocked, gtTemplate } from 'shared/utils/narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const getSiteAreaGeneratedText = () => {
  const downwardCompsSentence = gtLocked('downwardCompsSentence')
  const upwardCompsSentence = gtLocked('upwardCompsSentence')

  const downwardsMultiples = gtConditional('hasMultipleDownwardAdjustments', gtTemplate`s`)
  const downwardsAdjustmentSentence = gtConditional(
    'hasDownwardAdjustments',
    gtTemplate`Comparable${downwardsMultiples} ${downwardCompsSentence} ${gtIfElse(
      'hasMultipleDownwardAdjustments',
      gtTemplate`have`,
      gtTemplate`has a`
    )} larger site area, and thus required${gtIfElse(
      'hasMultipleDownwardAdjustments',
      gtTemplate``,
      gtTemplate` a`
    )} downward adjustment${downwardsMultiples}.`
  )

  const upwardsMultiples = gtConditional('hasMultipleUpwardAdjustments', gtTemplate`s`)
  const upwardsAdjustmentSentence = gtConditional(
    'hasUpwardAdjustments',
    gtTemplate`Comparable${upwardsMultiples} ${upwardCompsSentence} ${gtIfElse(
      'hasMultipleUpwardAdjustments',
      gtTemplate`have`,
      gtTemplate`has a`
    )} smaller site area, and thus required${gtIfElse(
      'hasMultipleUpwardAdjustments',
      gtTemplate``,
      gtTemplate` an`
    )} upward adjustment${upwardsMultiples}.`
  )

  const siteAreaSentence = gtTemplate`${downwardsAdjustmentSentence}${gtConditional(
    'hasDownwardAdjustments',
    gtTemplate` `
  )}${upwardsAdjustmentSentence}`

  const writeUpSentence = gtIfElse(
    'hasAdjustments',
    gtTemplate`${siteAreaSentence}`,
    gtTemplate`No adjustments were necessary as the comparables have similar site area.`
  )

  return writeUpSentence
}

const generate = () => {
  const gt = new GeneratedText()

  gt.t`${getSiteAreaGeneratedText()}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
