import { ADJUSTMENT_GROUPS } from 'shared/helpers/salesApproach/salesCompAdjustmentConstants'
import { mapDataModelForAdjustmentsNarrative } from 'shared/utils/textGeneration/salesApproach'

const mapData = ({ compAdjustments, selectedComps }) => {
  const {
    hasDownwardAdjustments,
    hasMultipleDownwardAdjustments,
    hasUpwardAdjustments,
    hasMultipleUpwardAdjustments,
    downwardCompsSentence,
    upwardCompsSentence,
  } = mapDataModelForAdjustmentsNarrative({ compAdjustments, selectedComps }, 'zoning', ADJUSTMENT_GROUPS.OTHER)
  return {
    hasAdjustments: hasDownwardAdjustments || hasUpwardAdjustments,
    hasDownwardAdjustments,
    hasMultipleDownwardAdjustments,
    downwardCompsSentence,
    hasUpwardAdjustments,
    hasMultipleUpwardAdjustments,
    upwardCompsSentence,
  }
}

export const mapDTO = (formValues: any) => {
  return mapData(formValues)
}

export const mapDataModel = (report: any, parent: any) => {
  const salesApproach = parent.parent()

  if (!salesApproach) {
    return undefined
  }

  const { salesComps = {}, salesCompsAdjustments = {} } = salesApproach
  const { selectedComps = [] } = salesComps
  const { compAdjustments = [] } = salesCompsAdjustments
  return mapData({ selectedComps, compAdjustments })
}
