import { get } from 'lodash'

import { put, select, takeLatest } from 'redux-saga/effects'

import BoweryDate from '@bowery-valuation/bowery-date'

import * as Api from '../../../api'
import * as Socket from '../../../../shared/utils/socket'
import { errorNotification } from '../../../../shared/redux/actions/notifications'

import {
  fetchExports,
  fetchExportsRequest,
  fetchExportsFail,
  fetchExportsReceive,
  updateStatus,
  generateReport,
  generateReportRequest,
  generateReportReceive,
  generateReportFail,
  generateReportFromJSON,
  generateReportFromJSONRequest,
  generateReportFromJSONReceive,
  generateReportFromJSONFail,
  getReportHierarchy,
  getReportHierarchyRequest,
  getReportHierarchyReceive,
  getReportHierarchyFail,
  init,
  generateXML,
} from './actions'

function* fetchExportsHandler() {
  try {
    const reportId = yield select(state => get(state, 'report.reportData._id'))

    yield put(fetchExportsRequest(reportId))
    const response = yield Api.fetchExports(reportId)
    yield put(fetchExportsReceive(response))
  } catch (err) {
    yield put(fetchExportsFail(err))
  }
}

function* getReportHierarchyHandler(action) {
  try {
    const baseTemplateFileName = action.payload

    yield put(getReportHierarchyRequest())
    const response = yield Api.getReportHierarchy(baseTemplateFileName)
    yield put(getReportHierarchyReceive(response))
  } catch (err) {
    yield put(getReportHierarchyFail(err))
  }
}

function* initHandler() {
  Socket.on('report:export', function* (data) {
    const { _id, progress, status } = data[0]

    yield put(
      updateStatus({
        _id,
        progress,
        status,
      })
    )
  })

  yield fetchExportsHandler()
}

function* generateReportHandler(action) {
  try {
    const reportId = yield select(state => get(state, 'report.reportData._id'))
    const reportDate = new BoweryDate().toLocalTime().format('YYYY-MM-DDTHH:mm:ss')
    const { baseTemplateFileName, isRefreshable } = action.payload
    const { selectedTemplates, exportTemplates, shouldGenerateSignatures } = yield select(state => get(state, 'export'))
    const excludedTemplates = exportTemplates
      .filter(template => !selectedTemplates.find(selectedTemplate => selectedTemplate === template.value))
      .map(template => template.value)

    const isLandComparisonApproachEnabled = yield select(state =>
      get(state, 'report.reportSettings.approaches.land', false)
    )

    if (!isLandComparisonApproachEnabled) {
      excludedTemplates.push('Template/./bowery-way/land-value-comparison-approach')
      excludedTemplates.push('Template/./freddie-mac/land-value-comparison-approach')
    }

    yield put(generateReportRequest(reportId))
    const response = yield Api.generateReport(reportId, {
      baseTemplateFileName,
      excludedTemplates,
      isRefreshable,
      reportDate,
      shouldGenerateSignatures,
    })
    yield put(generateReportReceive(response))
  } catch (err) {
    yield put(generateReportFail(err))
    yield put(
      errorNotification({
        message: `Error generating report. ${err.message}.`,
      })
    )
  }
}

function* generateXMLHandler(action) {
  try {
    const payload = action.payload
    const reportId = yield select(state => get(state, 'report.reportData._id'))
    const response = yield Api.xmlGeneration(reportId, payload.type)
    if (get(response, 'downloadUrl')) {
      window.open(get(response, 'downloadUrl'))
    }
  } catch (err) {
    yield put(
      errorNotification({
        message: `Error generating XML file. Please contact us quoting the report id.`,
      })
    )
  }
}

function* generateReportFromJSONHandler(action) {
  try {
    const reportDate = new BoweryDate().toLocalTime().format('YYYY-MM-DDTHH:mm:ss')
    const { reportId, baseTemplateFileName, json } = action.payload

    yield put(generateReportFromJSONRequest(reportId))
    const response = yield Api.generateReportFromJSON(reportId, {
      baseTemplateFileName,
      data: json,
      reportDate,
    })
    yield put(generateReportFromJSONReceive(response))
  } catch (err) {
    yield put(generateReportFromJSONFail(err))
  }
}

export default [
  takeLatest(fetchExports, fetchExportsHandler),
  takeLatest(init, initHandler),
  takeLatest(generateReport, generateReportHandler),
  takeLatest(getReportHierarchy, getReportHierarchyHandler),
  takeLatest(generateXML, generateXMLHandler),
  takeLatest(generateReportFromJSON, generateReportFromJSONHandler),
]
