import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import MaterialTooltip from '@mui/material/Tooltip'

const styles = theme => ({
  tooltip: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '-130px 14px',
    background: '#404858',
    padding: 3,
    color: '#FFFFFF',
    boxShadow: '0px 2px 8px rgba(0,15,68,0.12)',
    borderRadius: 4,
    fontFamily: 'Nunito Sans',
    fontSize: 12,
  },
})

class ImageTooltip extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']).isRequired,
    popperTemplate: PropTypes.node,
    children: PropTypes.node,
  }

  render() {
    const { classes, placement, popperTemplate } = this.props
    return (
      <MaterialTooltip
        placement={placement}
        title={<React.Fragment>{popperTemplate}</React.Fragment>}
        classes={{ tooltip: classes.tooltip }}
      >
        <span>{this.props.children}</span>
      </MaterialTooltip>
    )
  }
}

export default withStyles(styles)(ImageTooltip)
