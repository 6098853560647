import { get, isNumber, isNaN, maxBy, max, minBy, min, meanBy, mean, keyBy, pick, isNil } from 'lodash'

import BoweryDate from '@bowery-valuation/bowery-date'

import { extractGroupingParameters } from 'shared/helpers/incomeApproach'

import ResidentialCalculations from 'shared/report-calculations/income-approach/residential/residential-calculations'

import { LeaseStatusTypes } from '../../../../shared/constants/report/incomeApproach/residential'

const ReportCalculations = {
  getSubjectAreaCommercialVacancyByType(potentialGrossIncome, commercial) {
    const includedUses = Object.keys(get(commercial, 'includedUses', {}))
    const allSubjectAreaCommercialVacancies = {
      retail: potentialGrossIncome.commercialVsAreaRetail,
      medical: potentialGrossIncome.commercialVsAreaMedical,
      office: potentialGrossIncome.commercialVsAreaOffice,
      community: potentialGrossIncome.commercialVsAreaCommunity,
      industrial: potentialGrossIncome.commercialVsAreaIndustrial,
      undetermined: potentialGrossIncome.commercialVsAreaUndetermined,
      ...potentialGrossIncome.customUseSubjectAreaCommercialVacancy,
    }
    return pick(allSubjectAreaCommercialVacancies, includedUses)
  },

  getCommercialSubjectSuitabilityByType(potentialGrossIncome, commercial) {
    const includedUses = Object.keys(get(commercial, 'includedUses', {}))
    const allCommercialSubjectSuitabilities = {
      retail: potentialGrossIncome.commercialSpaceRetail,
      medical: potentialGrossIncome.commercialSpaceMedical,
      office: potentialGrossIncome.commercialSpaceOffice,
      community: potentialGrossIncome.commercialSpaceCommunity,
      industrial: potentialGrossIncome.commercialSpaceIndustrial,
      undetermined: potentialGrossIncome.commercialSpaceUndetermined,
      ...potentialGrossIncome.customUseCommercialSubjectSuitability,
    }
    return pick(allCommercialSubjectSuitabilities, includedUses)
  },

  roundToFactor(number, roundingFactor) {
    return roundingFactor * Math.round(number / roundingFactor)
  },

  /**
   * Form Income Array for Projection
   *
   * @description Taking a year 1 income and growth rate, project x years of income
   * @return      Array
   */
  createIncomeArray(startDate, yearsRemaining, y1, growthRate) {
    const _growthRate = parseFloat(growthRate)
    const base = y1
    const _yearsRemaining = parseInt(yearsRemaining, 10)
    const _startDate = new BoweryDate(startDate)
    const startYear = _startDate.year
    /* const startMonth = parseInt(startDate.getMonth()); */
    const date = new BoweryDate()
    const currentYear = date.year
    /* const currentMonth = parseInt(d.getMonth()); */
    const yearsSince = currentYear - startYear
    /* const currentYearProjected = base * Math.pow(1 + growthRate, yearsSince); */

    if (!isNaN(_growthRate) && !isNaN(base) && !isNaN(_yearsRemaining) && startYear) {
      /*
       const weightB = (currentMonth - startMonth);
       const weightA = 12 - weightB;
       const npv = 0; */
      const incomeArray = []

      for (let i = yearsSince; i < _yearsRemaining + yearsSince; i += 1) {
        const income = base * (1 + _growthRate) ** i
        incomeArray.push(income)
      }

      return incomeArray
    }
    return []
  },

  getTotalNpvAdjustments(npvAdjustments = []) {
    return npvAdjustments.reduce((total, item) => total + item.value, 0)
  },

  getMonthlyIncome(residential, increaseValue = true) {
    return ResidentialCalculations.getMonthlyIncome(residential, increaseValue)
  },

  getProjectedAnnualIncome(residential, increaseValue = true) {
    return ResidentialCalculations.getProjectedAnnualIncome(residential, increaseValue)
  },

  getTotalBedroomCount(residential) {
    return (residential.units || []).reduce((acc, unit) => acc + (unit.bedrooms || 0), 0)
  },

  getMonthlyMarketConclusionIncome(residential) {
    const { unitGroupingType, unitGroups } = residential
    const unitGroupsByKey = keyBy(unitGroups, 'key')

    return residential.units.reduce((sum, unit) => {
      const { key } = extractGroupingParameters(unit, unitGroupingType)
      const rentConclusion = unitGroupsByKey[key].rentConclusion
      const conclusion = rentConclusion.market_conclusion ? rentConclusion.market_conclusion : 0
      return sum + conclusion
    }, 0)
  },

  totalRoomCount(residential = {}) {
    let roomSum = 0
    const units = residential.units || []
    units.forEach(unit => {
      const rooms = isNil(unit.rooms) ? parseInt(unit.bedrooms, 10) + 2 : parseInt(unit.rooms, 10)
      roomSum += rooms
    })

    return roomSum
  },

  totalRoomCountPerBedroom(residential, bedrooms) {
    const allUnits = residential.units || []
    const filteredUnits = allUnits.filter(unit => unit.bedrooms === bedrooms)
    return this.totalRoomCount({ units: filteredUnits })
  },

  totalRoomCountPerUnitGroup(unitGroup) {
    return this.totalRoomCount({ units: unitGroup.units })
  },

  getVacantUnits(residential) {
    return ResidentialCalculations.getVacantUnits(residential)
  },

  getOccupancyRate(residential) {
    if (!residential.units || !residential.units.length) {
      return 0
    }
    return parseFloat((residential.units.length - this.getVacantUnits(residential).length) / residential.units.length)
  },

  getOccupancyRateWithoutEmployeeUnits(residential) {
    const rentRollUnitsWithoutEmployeeUnits = residential.units.filter(
      unit => unit.leaseStatus !== LeaseStatusTypes.EMPLOYEE
    )
    return this.getOccupancyRate({
      ...residential,
      units: rentRollUnitsWithoutEmployeeUnits,
    })
  },

  getNumberOfTenants(residential) {
    return residential.units.reduce(
      (sum, unit) => (unit.leaseStatus !== LeaseStatusTypes.EMPLOYEE && !unit.isVacant ? sum + 1 : sum),
      0
    )
  },

  getActualPerTransitionalAvRatio(actualAv, transitionalAv) {
    if (!actualAv || !transitionalAv) {
      return 0
    }
    return actualAv / transitionalAv - 1
  },

  getNetRentableArea(residential, totalCommercialArea = 0, hasPerUnitSquareFootage = false) {
    let netRentableArea
    if (hasPerUnitSquareFootage) {
      const totalResidentialArea = residential.units.reduce(
        (sum, currentUnit) => sum + parseFloat(get(currentUnit, 'sqft', 0)),
        0
      )
      netRentableArea = totalResidentialArea + totalCommercialArea
    } else {
      netRentableArea = residential.unitGroups.reduce((sum, unitGroup) => {
        const numberOfGroupUnits = unitGroup.units.length
        const averageGroupSqft = unitGroup.estimatedAverageSF || 0
        return sum + numberOfGroupUnits * averageGroupSqft
      }, 0)
    }
    return netRentableArea
  },

  getTotalSquareFootageForResidentialUnits(
    rentRollUnits = [],
    averageSfUnitCollection = {},
    doRentRollUnitsHaveSqft = false
  ) {
    let totalSqft = 0

    if (doRentRollUnitsHaveSqft) {
      totalSqft = rentRollUnits.reduce((sum, unit) => {
        const unitSqft = get(unit, 'sqft', 0)
        if (!isNaN(unitSqft) && isNumber(unitSqft)) {
          return sum + unitSqft
        }
        return sum
      }, 0)
    } else {
      totalSqft = rentRollUnits.reduce((sum, currentValue) => {
        const unitSqft = get(averageSfUnitCollection, `${currentValue.bedrooms}`)

        if (!isNaN(unitSqft) && isNumber(unitSqft)) {
          return sum + unitSqft
        }
        return sum
      }, 0)
    }

    return totalSqft
  },

  getTotalSqft(units) {
    return units.reduce((sum, unit) => sum + parseFloat(unit.sqft), 0)
  },

  getAverageSqftPerUnit(units) {
    return this.getTotalSqft(units) / units.length
  },

  getProjectedMarketPercentage(residential) {
    return ResidentialCalculations.getProjectedMarketPercentage(residential)
  },
  getAnnualIncreaseLookup(residential) {
    const annualIncreaseLookup = {}
    const rentTypeAnnualIncreases = get(residential, 'rentTypeAnnualIncreases', [])
    for (let i = 0; i < rentTypeAnnualIncreases.length; i++) {
      const annualIncrease = rentTypeAnnualIncreases[i]
      annualIncreaseLookup[annualIncrease.rentType] = annualIncrease.increase
    }
    return annualIncreaseLookup
  },

  getAnnualRentForUnits(units, unitMonthlyRentSource = 'rent') {
    if (!units.length) {
      return '--'
    }

    return units.reduce((sum, unit) => sum + parseFloat(unit[unitMonthlyRentSource]), 0) * 12
  },

  getMaxRentForUnits(units, includePerUnitSFRent = false, unitMonthlyRentSource = 'rent') {
    const resultObj = { unit: 0, psf: 0 }
    const maxUnit = maxBy(units, unitMonthlyRentSource)

    if (maxUnit) {
      resultObj.unit = maxUnit[unitMonthlyRentSource]
    }

    if (includePerUnitSFRent) {
      resultObj.psf = max(this.getUnitRentsBySF(units))
    }

    return resultObj
  },

  getMinRentForUnits(units, includePerUnitSFRent = false, unitMonthlyRentSource = 'rent') {
    const resultObj = { unit: 0, psf: 0 }
    const minUnit = minBy(units, unitMonthlyRentSource)

    if (minUnit) {
      resultObj.unit = minUnit[unitMonthlyRentSource]
    }

    if (includePerUnitSFRent) {
      resultObj.psf = min(this.getUnitRentsBySF(units))
    }

    return resultObj
  },

  getAvgRentForUnits(units, includePerUnitSFRent = false, unitMonthlyRentSource = 'rent') {
    const resultObj = { unit: 0, psf: 0 }

    resultObj.unit = meanBy(units, unit => parseFloat(unit[unitMonthlyRentSource]))

    if (includePerUnitSFRent) {
      resultObj.psf = mean(this.getUnitRentsBySF(units))
    }

    return resultObj
  },

  getUnitRentsBySF(units) {
    return units.map(unit => parseFloat((unit.rent * 12) / unit.sqft))
  },
}

export default ReportCalculations
