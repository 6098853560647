import React from 'react'
import classNames from 'classnames'
import { Typography, TableCell } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { blueGrey } from '@material-ui/core/colors'

export const Cell = withStyles(theme => ({
  root: {
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  },
}))(({ classes, className, children, ...rest }) => (
  <TableCell className={classNames(classes.root, className)} {...rest}>
    {children}
  </TableCell>
))

export const TotalCell = withStyles(theme => ({
  root: theme.totalCell,
}))(({ classes, className, children, ...rest }) => (
  <Cell className={classNames(classes.root, className)} {...rest}>
    {children}
  </Cell>
))

export const BaseUnitCell = withStyles({
  root: {
    background: blueGrey[50],
  },
})(({ classes, className, children, ...rest }) => (
  <Cell className={classNames(classes.root, className)} {...rest}>
    {children}
  </Cell>
))

export const TotalBaseUnitCell = withStyles({
  root: {
    background: blueGrey[50],
  },
})(({ classes, className, children, ...rest }) => (
  <TotalCell className={classNames(classes.root, className)} {...rest}>
    {children}
  </TotalCell>
))

export const SubjectUnitCell = withStyles({
  root: {
    background: blueGrey[200],
  },
})(({ classes, className, children, ...rest }) => (
  <Cell className={classNames(classes.root, className)} {...rest}>
    {children}
  </Cell>
))

export const TotalSubjectUnitCell = withStyles({
  root: {
    background: blueGrey[200],
  },
})(({ classes, className, children, ...rest }) => (
  <TotalCell className={classNames(classes.root, className)} {...rest}>
    {children}
  </TotalCell>
))

export const HeadingCell = ({ children, className = undefined, ...rest }) => (
  <Cell className={className} {...rest}>
    <Typography variant="subtitle1">{children}</Typography>
  </Cell>
)
