import { get, forIn, isNumber } from 'lodash'

export const zoningFloorArea = (grossBuildingArea, mechanicalDeductions) =>
  grossBuildingArea * (1 - mechanicalDeductions / 100)

export const getAdjustedRentPSF = (unit, psfRent, leaseTermsCalcType, useCustomAdjustments = false) => {
  const dateSignedTrendedPricePerSF = getDateSignedTrendedPricePSF(unit, psfRent, leaseTermsCalcType)
  const totalAdjustment = getTotalAdjustment(unit, useCustomAdjustments)

  return dateSignedTrendedPricePerSF * (1 + totalAdjustment)
}

export const getTotalAdjustment = (unit, useCustomAdjustments = false) => {
  let sum = 0
  const adjustments = get(unit, 'adjustments', {})

  forIn(adjustments, (value, key) => {
    if (key !== 'dateSigned' && key !== 'leaseTerms') {
      const val = parseFloat(value)
      if (!isNaN(val)) {
        sum += val
      }
    }
  })

  if (useCustomAdjustments) {
    const customAdjustments = get(unit, 'customAdjustments', {})
    forIn(customAdjustments, value => {
      const val = parseFloat(value)
      if (!isNaN(val)) {
        sum += val
      }
    })
  }

  return sum
}

export const getLeaseTermsSubTotalPSF = (unit, psfRent, calcType = '%') => {
  const leaseTermsAdjustment = get(unit, 'adjustments.leaseTerms', 0)
  let leaseTermsSubTotal = psfRent

  if (isNumber(psfRent) && isNumber(leaseTermsAdjustment)) {
    if (calcType === '$/SF') {
      leaseTermsSubTotal += leaseTermsAdjustment
    } else {
      leaseTermsSubTotal *= parseFloat(leaseTermsAdjustment) + 1
    }
  }

  return leaseTermsSubTotal
}

export const getDateSignedTrendedPricePSF = (unit, psfRent, calcType = '%') => {
  const marketConditionsAdjustment = get(unit, 'adjustments.dateSigned', 0)
  let totalPsfRent = psfRent

  if (isNumber(psfRent)) {
    totalPsfRent = getLeaseTermsSubTotalPSF(unit, psfRent, calcType)

    if (isNumber(marketConditionsAdjustment)) {
      totalPsfRent *= parseFloat(marketConditionsAdjustment) + 1
    }
  }

  return totalPsfRent
}

export const isValidNumber = value => isNumber(value) && !isNaN(value)
