import createDecorator from 'final-form-calculate'

import { LeaseStatusTypes } from '../../commercial/CommercialRentRoll/constants'

const averageSize = [
  createDecorator({
    field: 'grossLeasableAreaPercentage',
    updates: {
      grossLeasableArea: (percentage, allValues) => {
        const { grossResidentialArea } = allValues
        if (percentage >= 0 && grossResidentialArea >= 0) {
          return percentage * grossResidentialArea
        } else {
          return null
        }
      },
    },
  }),
]

const unitsDecorator = createDecorator({
  field: /units\[\d]\.leaseStatus/,
  updates: (value, fieldName, allValues) => {
    if (value === LeaseStatusTypes.Vacant) {
      const unitsIndex = fieldName.replace('.leaseStatus', '')
      return {
        [`${unitsIndex}.rent`]: 0,
        [`${unitsIndex}.leaseStartDate`]: null,
        [`${unitsIndex}.leaseExpiryDate`]: null,
      }
    }
    return {}
  },
})

export default [...averageSize, unitsDecorator]
