import React, { PropsWithChildren } from 'react'

import classnames from 'classnames'
import { withStyles } from '@mui/styles'
import { lightBlue } from '@mui/material/colors'

const style = (theme: any) => ({
  root: {
    backgroundColor: lightBlue[200],
    padding: 16,
    borderWidth: '0 1px 4px 1px',
    borderColor: lightBlue[500],
    '& *': {
      margin: 0,
    },
  },
})

type AlertInfoProps = {
  className?: string
  classes: any
}

const AlertInfo: React.FC<PropsWithChildren<AlertInfoProps>> = ({ classes, children, className }) => {
  return <div className={classnames(classes.root, className)}>{children}</div>
}

const AlertInfoWithStyles = withStyles(style)(AlertInfo)

export default AlertInfoWithStyles
