import { get, inRange, max, mean, min } from 'lodash'

import { AREA_CONDITIONS_TYPES, INCOME_POTENTIAL_TYPES, RISK_TYPES } from 'shared/constants/report/final'
import { toPercentageString } from 'shared/utils/formatters/numberFormatters'

import { VALUE_CONCLUSION_TYPES } from 'shared/constants/acas'
import { GENERAL_CONDITIONS } from 'shared/constants/property/description'

import { calculateMiddleRanges, getSurveySuperlatives } from 'shared/utils/helpers/investorSurverys'

import ResidentialCalculations from 'shared/report-calculations/income-approach/residential/residential-calculations'

import ReportCalculations from '../../../../../libs/bowery-libs/services/report-calculations'

import { getPropertyConditionSentencePart } from '../../property/description'

export const INVESTMENT_RISKS_ASSUMPTIONS = {
  potentialRisk:
    'suggesting potential risk of unscheduled capital investment necessary during the holding period and thus lending to a higher cap rate',
  typicalRisk: 'suggesting typical risk of unscheduled capital investment necessary during the holding period',
  limitedRisk: 'suggesting limited risk of unscheduled capital investment necessary during the holding period',
}

export const RISK_AND_POTENTIAL_LOOKUP = {
  Secure: {
    Significant: 'suggesting security to the cash flow, as well as significant potential for future income spikes.',
    Typical: 'suggesting security to the cash flow, with typical potential for future income spikes.',
    Moderate: 'suggesting security to the cash flow, with moderate potential for future income spikes.',
    Limited: 'suggesting security to the cash flow, but limited potential for future income spikes.',
  },
  'Short-term Risk': {
    Limited: 'suggesting some short-term risk to the cash flow, as well as limited potential for future income spikes.',
    Moderate:
      'suggesting some short-term risk to the cash flow, as well as moderate potential for future income spikes.',
    Typical: 'suggesting some short-term risk to the cash flow, with typical potential for future income spikes.',
    Significant:
      'suggesting some short-term risk to the cash flow, but significant potential for future income spikes.',
  },
  'Moderate Risk': {
    Limited: 'suggesting moderate risk to the cash flow, as well as limited potential for future income spikes.',
    Moderate: 'suggesting moderate risk to the cash flow, as well as moderate potential for future income spikes.',
    Typical: 'suggesting moderate risk to the cash flow, with typical potential for future income spikes.',
    Significant: 'suggesting moderate risk to the cash flow, but significant potential for future income spikes.',
  },
  'Do Not Discuss': {
    Limited: 'suggesting limited potential for future income spikes.',
    Moderate: 'suggesting moderate potential for future income spikes.',
    Typical: 'suggesting typical potential for future income spikes.',
    Significant: 'suggesting significant potential for future income spikes.',
  },
  SPECIAL: {
    Limited:
      'Given the demand for similar rental units in the subject’s market, we consider the cash flow forecast secure, as vacancies pose nominal risk. On the other hand, the subject has limited potential for future income spikes.',
    Moderate:
      'Given the demand for similar rental units in the subject’s market, we consider the cash flow forecast secure, as vacancies pose nominal risk. Further, the subject has moderate potential for future income spikes.',
    Typical:
      'Given the demand for similar rental units in the subject’s market, we consider the cash flow forecast secure, as vacancies pose nominal risk. Further, the subject has typical potential for future income spikes.',
    Significant:
      'Given the demand for similar rental units in the subject’s market, we consider the cash flow forecast secure, as vacancies pose nominal risk. Further, the subject has significant potential for future income spikes.',
  },
}

const getCapRateRelativeToComps = (capRateRoundedTo, investorSurveys) => {
  if (capRateRoundedTo > investorSurveys.higherEnd.max) {
    return 'above'
  }
  if (inRange(capRateRoundedTo, investorSurveys.lowerEnd.min, investorSurveys.lowerEnd.max)) {
    return 'toward the lower end'
  }
  if (inRange(capRateRoundedTo, investorSurveys.middle.min, investorSurveys.middle.max)) {
    return 'toward the middle'
  }
  if (
    inRange(capRateRoundedTo, investorSurveys.higherEnd.min, investorSurveys.higherEnd.max) ||
    capRateRoundedTo === investorSurveys.higherEnd.max
  ) {
    return 'toward the higher end'
  }
  if (capRateRoundedTo < investorSurveys.min) {
    return 'below'
  }
  return 'N/A'
}

const qualifiesForSpecialDiscussion = ({ resUnits, occupancyLevel, cashFlowRiskProfile, numberOfVacantUnits }) => {
  if (resUnits >= 10 && occupancyLevel < 0.9 && cashFlowRiskProfile === 'Short-term Risk') {
    return true
  }
  if (resUnits > 2 && resUnits < 10 && numberOfVacantUnits > 1 && cashFlowRiskProfile === 'Short-term Risk') {
    return true
  }
  return false
}

export const getCapRateRiskAndPotential = ({
  occupancyLevel,
  numberOfVacantUnits,
  resUnits,
  cashFlowRiskProfile,
  subjectIncomePotential,
}) => {
  if (!cashFlowRiskProfile || !subjectIncomePotential) {
    return ''
  }

  const isQualifiesForSpecialDiscussion = qualifiesForSpecialDiscussion({
    resUnits,
    occupancyLevel,
    cashFlowRiskProfile,
    numberOfVacantUnits,
  })

  const lookupKey = [isQualifiesForSpecialDiscussion ? 'SPECIAL' : cashFlowRiskProfile, subjectIncomePotential]
  return get(RISK_AND_POTENTIAL_LOOKUP, lookupKey)
}

const getCapRateRelativeToMarket = (averageCompCapRate, investorSurveys) => {
  let capRateRelativeToMarket = ''
  if (averageCompCapRate > investorSurveys.max) {
    capRateRelativeToMarket = 'above'
  }
  if (inRange(averageCompCapRate, investorSurveys.lowerEnd.min, investorSurveys.lowerEnd.max)) {
    capRateRelativeToMarket = 'toward the lower end of'
  }
  if (inRange(averageCompCapRate, investorSurveys.middle.min, investorSurveys.middle.max)) {
    capRateRelativeToMarket = 'toward the middle of'
  }
  if (inRange(averageCompCapRate, investorSurveys.higherEnd.min, investorSurveys.higherEnd.max)) {
    capRateRelativeToMarket = 'toward the higher end of'
  }
  if (averageCompCapRate < investorSurveys.min) {
    capRateRelativeToMarket = 'below'
  }
  return capRateRelativeToMarket
}

export const getInvestmentRiskAssumption = condition => {
  switch (condition.value) {
    case GENERAL_CONDITIONS.fair:
      return INVESTMENT_RISKS_ASSUMPTIONS.potentialRisk
    case GENERAL_CONDITIONS.satisfactory:
      return INVESTMENT_RISKS_ASSUMPTIONS.typicalRisk
    case GENERAL_CONDITIONS.good:
    case GENERAL_CONDITIONS.excellent: {
      return INVESTMENT_RISKS_ASSUMPTIONS.limitedRisk
    }
    default: {
      return null
    }
  }
}

export const getCapRateSubjectMarketConditions = ({ currentMarketConditions }) => {
  if (!currentMarketConditions) {
    return ''
  }

  switch (currentMarketConditions) {
    case 'Moderately Appreciating':
      return 'Additionally, it is in a market where there has been moderate demand for investment and capitalization rates have remained relatively steady.'
    case 'Growing':
      return 'Additionally, it is in a market where there has been increased demand for investment and capitalization rates have continued to fall.'
    case 'Continually Strong':
      return 'Additionally, it is in a market where there has been continually strong demand for investment and capitalization rates have been continually strong.'
    default:
      return ''
  }
}
export const getCapRateAssetCondition = ({ propertyCondition, asStabilizedPropertyCondition, valueConclusionType }) => {
  propertyCondition =
    valueConclusionType === VALUE_CONCLUSION_TYPES.AS_COMPLETE ? asStabilizedPropertyCondition : propertyCondition

  const investmentRiskAssumption = getInvestmentRiskAssumption(propertyCondition)

  const valueConclusionASComplete = valueConclusionType === VALUE_CONCLUSION_TYPES.AS_COMPLETE

  return `The asset ${valueConclusionASComplete ? 'will be' : 'is'} ${getPropertyConditionSentencePart(
    propertyCondition
  )}${investmentRiskAssumption ? `, ${investmentRiskAssumption}.` : '.'}`
}

const mapData = values => {
  const {
    concludedCapRate,
    percentageOfMarketRent,
    occupancyLevel,
    compBuckets,
    capRateCompsAverage,
    investorSurveys,
  } = values
  const capRateRelativeToComps = getCapRateRelativeToComps(concludedCapRate, compBuckets)
  const doesQualifyForSpecialDiscussion = qualifiesForSpecialDiscussion(values)

  const capRateRiskAndPotential = getCapRateRiskAndPotential(values)
  const capRateAssetCondition = getCapRateAssetCondition(values)
  const capRateSubjectMarketConditions = getCapRateSubjectMarketConditions(values)
  const capRateRelativeToMarket = getCapRateRelativeToMarket(capRateCompsAverage, investorSurveys)

  return {
    percentageOfMarketRent: toPercentageString(percentageOfMarketRent),
    occupancyLevel: toPercentageString(occupancyLevel),
    capRateRelativeToComps,
    capRateRelativeToMarket,
    doesQualifyForSpecialDiscussion,
    capRateRiskAndPotential,
    capRateAssetCondition,
    capRateSubjectMarketConditions,
    noAdditionalInfo:
      (!capRateRiskAndPotential && !capRateAssetCondition && !capRateSubjectMarketConditions) ||
      doesQualifyForSpecialDiscussion,
  }
}

export const mapDTO = formValues => {
  return mapData(formValues)
}

export const mapDataModel = report => {
  const capRateComps = get(report, 'cap_rate_comps', []).map(capRateComp => capRateComp.capRate)

  const nationalSurveyInvestorResponses = get(report, 'cap_rate_recon.nationalSurveyResponses', {})
  const investorSurveys = getSurveySuperlatives(nationalSurveyInvestorResponses)
  const capRateCompsMin = min(capRateComps)
  const capRateCompsMax = max(capRateComps)

  const compBuckets = calculateMiddleRanges(capRateCompsMax, capRateCompsMin)

  return mapData({
    concludedCapRate: get(report, 'cap_rate.rounded_to'),
    percentageOfMarketRent: ResidentialCalculations.getProjectedMarketPercentage(report.residential) || 0,
    occupancyLevel: ReportCalculations.getOccupancyRate(report),
    compBuckets,
    capRateCompsAverage: mean(capRateComps),
    investorSurveys,
    resUnits: get(report, 'property_information.resUnits'),
    cashFlowRiskProfile: RISK_TYPES[get(report, 'cap_rate_recon.risk_profile', null)],
    numberOfVacantUnits: ResidentialCalculations.getVacantUnits(report.residential).length,
    subjectIncomePotential: INCOME_POTENTIAL_TYPES[get(report, 'cap_rate_recon.subject_income_potential')],
    propertyCondition: get(report, 'buildingDescription.generalCondition'),
    asStabilizedPropertyCondition: get(report, 'buildingDescription.generalAsStabilizedCondition'),
    valueConclusionType: get(report, 'valueConclusionType'),
    currentMarketConditions: AREA_CONDITIONS_TYPES[get(report, 'cap_rate_recon.crr_subject_market', null)],
  })
}
