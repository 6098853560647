import React from 'react'

import Link from 'client-shared/components/Link'
import { BooleanRadioButtonList } from 'client-shared/components'
import { Box, Button, FormLabel, Stack, Typography } from '@mui/material'
import { OnChange } from 'react-final-form-listeners'

import CreateReportRadioButtons from '../../../../report/components/ReportSettings/ReportSettings'
import { BlocksReportButtons, ToolSelectionCheckboxes } from '../BlocksReportFlow'

import JobSyncCallout from './JobSyncCallout'
import { JobSyncInputField, JobInputField } from './boweryJobInput'
import { PULL_EXTERNAL_DATA_OPTIONS } from './constants'

const ReportSettingsView = ({
  fetchReportSettings,
  handleSubmit,
  invalid,
  isBlocks,
  onPullExternalDataChange,
  openPreviousStep,
  previousStep,
  pullExternalData,
  resetReportSettings,
  shouldRenderSFJobInput,
  syncState,
}) => (
  <Stack>
    <BlocksReportButtons name="settings.isBlocks" />
    {!isBlocks && (
      <Box sx={{ mb: -1, mt: -1 }}>
        <Box sx={{ mb: 1 }}>
          <FormLabel>
            <Typography variant="subtitle2" fontWeight="700">
              Pull External Data
            </Typography>
          </FormLabel>
        </Box>
        <Box sx={{ mt: -2, mb: 2 }}>
          <OnChange name="settings.pullExternalData">{onPullExternalDataChange}</OnChange>
          <BooleanRadioButtonList
            disabled={syncState.inProgress}
            horizontal
            items={PULL_EXTERNAL_DATA_OPTIONS}
            name="settings.pullExternalData"
          />
        </Box>
      </Box>
    )}
    {!isBlocks && shouldRenderSFJobInput && (
      <Box sx={{ mb: 1 }}>
        <JobSyncCallout />
        <JobSyncInputField
          syncState={syncState}
          onSyncRequested={fetchReportSettings}
          onUnsyncRequested={resetReportSettings}
        />
      </Box>
    )}
    {isBlocks && (
      <Box sx={{ mt: 2, mb: -1.5 }}>
        <JobInputField />
      </Box>
    )}
    {!isBlocks && !shouldRenderSFJobInput && <JobInputField />}
    {isBlocks && <ToolSelectionCheckboxes />}
    {!isBlocks && <CreateReportRadioButtons disabled={pullExternalData && !syncState.completed} />}
    <Stack alignItems="center" justifyContent="space-between" direction="row">
      <Link data-qa="backToResults-btn" onClick={openPreviousStep}>
        {`Back to ${previousStep}`}
      </Link>

      <Button data-qa="create-report-btn" disabled={invalid} onClick={handleSubmit}>
        Create Report
      </Button>
    </Stack>
  </Stack>
)

ReportSettingsView.displayName = 'ReportSettingsView'

export default ReportSettingsView
