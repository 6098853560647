export const UNSORTED_GROUP_ID = 'unsorted_group'
export const NOT_AVAILABLE = '-'

export const Labels = {
  COMMERCIAL_COMP_GROUPS: 'Comp Groups',
  UNSORTED: 'Unsorted',
  ADD_COMP_GROUP: 'Add Comp Group',
  MOVE: 'Move',
  TENANT_NAME: 'Tenant Name',
  DATE_SIGNED: 'Date Signed',
  LEASE_EXPIRATION_DATE: 'Lease Expiration Date',
  PSF_RENT: 'Rent Per SF',
  MONTHLY_PSF_RENT: 'Rent Per SF/Month',
  LEASE_TERMS: 'Lease Terms',
  BASE_UNIT: 'Base Unit',
  DROP_ANY_RENT_ROLL_UNIT_HERE: 'Drop any rent roll unit here',
  THERE_ARE_NO_COMMERCIAL_UNITS: 'There are no commercial units',
  UPDATE_COMP_GROUP: 'Update Comp Group',
  GROUP_NAME: 'Group Name',
  GROUP_NAME_IS_REQUIRED: 'Group name is required',
  DELETE_COMP_GROUP: 'Delete Comp Group',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_COMP_GROUP:
    'Are you sure you want to delete comp group? All rent roll units will be moved to unsorted',
  CANCEL: 'Cancel',
  OK: 'Ok',
}
