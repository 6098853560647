import React, { useMemo } from 'react'
import { get, mean, isEmpty } from 'lodash'
import { useFeatureFlag } from '@bowery-valuation/feature-flagger-client'

import { ENABLE_COMMERCIAL_ADJUSTMENT_GRID_V2 } from 'shared/constants/featureFlags'

import {
  getAdjustedRentPSF,
  getLeaseTermsSubTotalPSF,
  getDateSignedTrendedPricePSF,
  getTotalAdjustment,
} from '../../../../../shared/utils/calculations'

import { getRentPerSF } from '../../../../../../../shared/calculations/commercial'

import CommercialRentReconciliationItem from './CommercialRentReconciliationItem'
import CommercialRentReconciliationItemV2 from './CommercialRentReconciliationItemV2'

const mapRentCompsData = (
  reconciliationGroupData: { summary: { rentComps: any[]; leaseTermsCalcType: string } },
  rentRollRentBasis: string,
  useCommercialAdjustmentsV2: boolean = false
) => {
  const reconciliationRentComps = get(reconciliationGroupData, 'summary.rentComps') || []
  const leaseTermsCalcType = get(reconciliationGroupData, 'summary.leaseTermsCalcType') || '%'
  return reconciliationRentComps.map((rentComp: any) => {
    const psfRent = getRentPerSF(rentComp, rentRollRentBasis)
    const leaseTermsAdjustmentSubTotal = getLeaseTermsSubTotalPSF(rentComp, psfRent, leaseTermsCalcType)
    const dateSignedTrendedPricePerSF = getDateSignedTrendedPricePSF(rentComp, psfRent, leaseTermsCalcType)

    const totalAdjustment = getTotalAdjustment(rentComp, useCommercialAdjustmentsV2)

    const adjustedRentPerSF = getAdjustedRentPSF(rentComp, psfRent, leaseTermsCalcType, useCommercialAdjustmentsV2)

    return {
      ...rentComp,
      psfRent,
      leaseTermsAdjustmentSubTotal,
      dateSignedTrendedPricePerSF,
      totalAdjustment,
      adjustedRentPerSF,
    }
  })
}

const mapUnit = (unit: any, leaseTermsCalcType: string) => {
  const { _id, rentPsf, squareFeet, tenantName } = unit
  const { frontage, ceilingHeight, location } = unit.description || {}
  const { signedAt, terms } = unit.lease || {}

  const totalAdjustment = getTotalAdjustment(unit)

  const leaseTermsAdjustmentSubTotal = getLeaseTermsSubTotalPSF(unit, rentPsf, leaseTermsCalcType)
  const dateSignedTrendedPricePerSF = getDateSignedTrendedPricePSF(unit, rentPsf, leaseTermsCalcType)

  const adjustedRentPerSF = getAdjustedRentPSF(totalAdjustment, dateSignedTrendedPricePerSF, leaseTermsCalcType)

  return {
    _id,
    squareFeet,
    tenantName,
    frontage,
    ceilingHeight,
    location,
    leaseTermsAdjustmentSubTotal,
    dateSignedTrendedPricePerSF,
    dateSigned: signedAt,
    leaseTerms: terms,
    psfRent: rentPsf,
    totalAdjustment,
    adjustedRentPerSF,
  }
}

const mapUnitsData = (units: any[], leaseTermsCalcType: string) => {
  return units.map((unit: any) => {
    return mapUnit(unit, leaseTermsCalcType)
  })
}

const getAverageAdjustedRentPSF = (
  rentCompsData: { adjustedRentPerSF: number }[],
  commercialUnits: { adjustedRentPerSF: number }[]
) => {
  const allAdjustedRents = [...rentCompsData, ...commercialUnits]
  return mean(allAdjustedRents.map(adjustedRent => adjustedRent.adjustedRentPerSF))
}

type CommercialRentReconciliationContainerProps = {
  index: number
  reconciliationGroupData: any
  rentRollRentBasis: string
  form: any
}

const CommercialRentReconciliationContainer: React.FC<CommercialRentReconciliationContainerProps> = ({
  index,
  reconciliationGroupData,
  rentRollRentBasis,
  form,
}) => {
  const isGroupValid = useMemo(() => {
    const baseUnit = get(reconciliationGroupData, 'summary.baseUnit') || {}
    const rentComps = get(reconciliationGroupData, 'summary.rentComps')

    return !isEmpty(baseUnit) && rentComps && rentComps.length > 0
  }, [reconciliationGroupData])

  const includeInExport = get(reconciliationGroupData, 'summary.includeInExport', true)
  const leaseTermsCalcType = get(reconciliationGroupData, 'summary.leaseTermsCalcType') || '%'
  const baseUnit = get(reconciliationGroupData, 'summary.baseUnit')
  const units = get(reconciliationGroupData, 'summary.commercialUnits') || []

  const baseUnitTenantName = get(baseUnit, 'tenantName')
  const baseUnitSquareFeet = get(baseUnit, 'squareFeet', 1)

  const baseUnitPerSFRent = get(baseUnit, 'rentPsf')

  const baseUnitDateSigned = get(baseUnit, 'lease.signedAt')
  const baseUnitLeaseTerms = get(baseUnit, 'lease.terms')
  const baseUnitFrontage = get(baseUnit, 'description.frontage')
  const baseUnitCeilingHeight = get(baseUnit, 'description.ceilingHeight')
  const baseUnitLocation = get(baseUnit, 'description.location')

  const useCommercialAdjustmentsV2 = useFeatureFlag(ENABLE_COMMERCIAL_ADJUSTMENT_GRID_V2)

  const rentCompsData = mapRentCompsData(reconciliationGroupData, rentRollRentBasis, useCommercialAdjustmentsV2)
  const baseUnitData = mapUnit(baseUnit, leaseTermsCalcType)
  const unitsData = mapUnitsData(units, leaseTermsCalcType)

  const averageAdjustmentRentPSF = getAverageAdjustedRentPSF(rentCompsData, unitsData)

  const fieldPrefix = `reconciliationGroups.items[${index}].summary`

  return useCommercialAdjustmentsV2 ? (
    <CommercialRentReconciliationItemV2
      fieldPrefix={fieldPrefix}
      leaseTermsCalcType={leaseTermsCalcType}
      groupName={reconciliationGroupData.name}
      baseUnit={baseUnitData}
      units={unitsData}
      rentComps={rentCompsData}
      includeInExport={includeInExport}
      isGroupValid={isGroupValid}
      rentRollRentBasis={rentRollRentBasis}
      form={form}
    />
  ) : (
    <CommercialRentReconciliationItem
      fieldPrefix={fieldPrefix}
      reconciliationGroupData={reconciliationGroupData}
      baseUnitTenantName={baseUnitTenantName}
      baseUnitSquareFeet={baseUnitSquareFeet}
      baseUnitPerSFRent={baseUnitPerSFRent}
      baseUnitDateSigned={baseUnitDateSigned}
      baseUnitLeaseTerms={baseUnitLeaseTerms}
      baseUnitFrontage={baseUnitFrontage}
      baseUnitCeilingHeight={baseUnitCeilingHeight}
      baseUnitLocation={baseUnitLocation}
      units={unitsData}
      rentComps={rentCompsData}
      averageAdjustmentRentPSF={averageAdjustmentRentPSF}
      includeInExport={includeInExport}
      isGroupValid={isGroupValid}
      rentRollRentBasis={rentRollRentBasis}
    />
  )
}

export default CommercialRentReconciliationContainer
