import React from 'react'
import PropTypes from 'prop-types'
import { DragDropContext } from 'react-beautiful-dnd'

import { FeatureToggle } from '@bowery-valuation/feature-flagger-client'
import {
  Avatar,
  Icon,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  Button,
  TableRow,
  Tooltip,
  Typography,
  Stack,
  IconButton,
  Box,
} from '@mui/material'
import StarRounded from '@mui/icons-material/StarRounded'
import RemoveCircle from '@mui/icons-material/RemoveCircle'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import { forEach, get, invert, isEqual, isNumber, noop, startCase } from 'lodash'
import { FieldArray } from 'react-final-form-arrays'

import { DraggableTableRow, DroppableTable } from 'client-shared/components/DragDropTable'
import {
  getBasis,
  getCurrencyFormatter,
  getSalePriceToUse,
  getUnitOfComparisonText,
} from 'shared/report-calculations/sales-approach/helpers'
import { divide } from 'shared/utils/numberOperations'
import { Area, Template } from 'client-shared/components/Template'
import { DropDown } from 'client-shared/components'
import { calculateDistance } from 'client-shared/utils/geography'
import { formatCurrencyInt, formatInt } from 'client-shared/utils/numberFormatters'

import { SALES_APPROACH_TYPES, EXPORT_IN_VALUES } from 'shared/constants/salesApproach'
import { STATE_NAMES } from 'shared/constants/states'

import AutoModeIcon from '@mui/icons-material/AutoModeRounded'

import { FEATURE_FLAG_AUTO_SALES_COMPS_RULE_EDIT } from 'shared/constants/featureFlags'

import Badge from './Badge'
import {
  EXPORT_IN_DROPDOWN_ITEMS,
  SALES_COMPS_SORT_DROPDOWN_ITEMS,
  SALES_COMPS_SORT_VALUES,
  SALES_COMP_STOCK_PHOTO,
} from './constants'
import { checkIfCompsAreLatestVersion, getFormattedSaleDate } from './helpers'
import { getSaleCompFullAddress } from './tools'
import { getSourceOfInformation } from './RemovedSalesCompsTable'

export const LAND_DEFAULT_HEADER_COLUMNS = [
  { id: 'sourceIcon', padding: 'none', label: '' },
  { id: 'photo', padding: 'none', label: 'Photo', style: { width: 40 } },
  { id: 'distance', padding: 'none', label: 'Distance' },
  { id: 'address', padding: 'none', label: 'Address', style: { minWidth: 150 } },
  { id: 'status', padding: 'none', label: 'Status' },
  { id: 'saleDate', padding: 'none', label: 'Date Sold' },
  { id: 'salePrice', padding: 'none', label: 'Sale Price' },
  { id: 'salePricePerBasis', padding: 'none', label: 'Sale Price per Basis' },
  { id: 'zoning', padding: 'none', label: 'Zoning' },
  { id: 'buildableUnits', padding: 'none', label: 'Buildable Units' },
  { id: 'buildableArea', padding: 'none', label: 'Max Buildable Area' },
  { id: 'sourceOfInformation', padding: 'none', label: 'Source Of Information' },
]

class MaterialTableHeader extends React.Component {
  render() {
    const { sortBy, unitOfComparison } = this.props
    return (
      <TableHead>
        <TableRow>
          <TableCell>{sortBy === SALES_COMPS_SORT_VALUES.CUSTOM && 'Order'}</TableCell>
          {LAND_DEFAULT_HEADER_COLUMNS.map(row => {
            if (row.id === 'salePricePerBasis') {
              const unitOfComparisonText = startCase(getUnitOfComparisonText(unitOfComparison))
              return (
                <TableCell padding={row.padding} key={row.id} style={{ ...row.style }}>
                  Sale Price Per {unitOfComparisonText}
                </TableCell>
              )
            } else {
              return (
                <TableCell padding={row.padding} key={row.id} style={{ ...row.style }}>
                  {row.label}
                </TableCell>
              )
            }
          }, this)}
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
    )
  }
}

const EnhancedTableHead = MaterialTableHeader

class SelectedLandCompsTable extends React.Component {
  state = {
    selected: [],
    draggingId: '',
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (this.props.salesComps.length < nextProps.salesComps.length) {
      this.setState({
        order: 'asc',
        orderBy: 'dateSold',
      })
    }
  }

  static propTypes = {
    form: PropTypes.object.isRequired,
    fields: PropTypes.shape({
      value: PropTypes.array,
      push: PropTypes.func,
      update: PropTypes.func,
    }),
    title: PropTypes.string.isRequired,
    removeComp: PropTypes.func.isRequired,
    navigateToEditModal: PropTypes.func.isRequired,
    subjectCoordinates: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    salesComps: PropTypes.array,
    subjectProperty: PropTypes.object,
    authenticatedUser: PropTypes.object.isRequired,
    fieldName: PropTypes.string.isRequired,
    getCustomDetailsButton: PropTypes.func,
  }

  static defaultProps = {
    getCustomDetailsButton: noop,
  }

  componentDidMount() {
    this.checkIfCompsAreLatestVersion()
  }

  checkIfCompsAreLatestVersion = async () => {
    const { form, fields, salesComps } = this.props

    const updatedComps = await checkIfCompsAreLatestVersion(salesComps)

    form.batch(() => {
      form.reset(form.getState().values)
      updatedComps.forEach((updatedComp, index) => {
        fields.update(index, updatedComp)
      })
    })
  }

  fillTableRowTemplate = ({
    compId,
    compIndex,
    photoElement,
    distance,
    streetAddress,
    saleDate,
    pricePerBasis,
    buildableArea,
    buildableUnits,
    zoning,
    salePrice,
    saleStatus,
    actions,
    displaySourceOfInformation,
    state,
    city,
    zip,
    isDrmComp,
    isLatestVersion,
    status,
    sourceIcon,
    isDeleted,
  }) => {
    const { sortBy } = this.props
    const fillerIcon = <Icon>remove</Icon>
    const buildableAreaDisplay = buildableArea ? `${formatInt(buildableArea?.value)} SF` : fillerIcon
    const buildableUnitsDisplay = buildableUnits ? `${formatInt(buildableUnits?.value || buildableUnits)}` : fillerIcon
    const isUneditable = compId && (!isDrmComp || !isLatestVersion)
    return (
      <DraggableTableRow
        data-qa={isNumber(compIndex) ? `selected-comps-row-${compIndex}` : 'property-row'}
        id={`selected-comps-${compId}`}
        index={isNumber(compIndex) ? compIndex : -1}
        isDragDisabled={sortBy !== SALES_COMPS_SORT_VALUES.CUSTOM || !isNumber(compIndex)}
        key={`selected-comps-${compId}`}
      >
        <TableCell data-qa="sourceIcon">{sourceIcon}</TableCell>
        <TableCell data-qa="photo-element">{photoElement}</TableCell>
        <TableCell data-qa="distance">{distance || fillerIcon}</TableCell>
        <TableCell data-qa="address">
          {getSaleCompFullAddress({ streetAddress, state, city, zip })}

          {isUneditable && (
            <Tooltip placement="top" title="Outdated">
              <Box component="span" sx={{ position: 'relative', top: 4, left: 8 }}>
                <ErrorOutline color="error" fontSize="small" />
              </Box>
            </Tooltip>
          )}

          {isDeleted && (
            <Tooltip placement="top" title="This comp was deleted from our database by a user.">
              <Box component="span" sx={{ position: 'relative', top: 4, left: 8 }}>
                <RemoveCircle color="error" fontSize="small" />
              </Box>
            </Tooltip>
          )}
        </TableCell>
        <TableCell data-qa="status">{status ? <Badge type={status} /> : fillerIcon}</TableCell>
        <TableCell data-qa="sale-date">{getFormattedSaleDate(saleDate, saleStatus, fillerIcon)}</TableCell>
        <TableCell data-qa="sale-price">{salePrice || fillerIcon}</TableCell>
        <TableCell data-qa="sale-price-per-basis">{pricePerBasis || fillerIcon}</TableCell>
        <TableCell data-qa="zoning">{zoning || fillerIcon}</TableCell>
        <TableCell data-qa="buildableUnits">{buildableUnitsDisplay}</TableCell>
        <TableCell data-qa="buildableArea">{buildableAreaDisplay}</TableCell>
        <TableCell data-qa="source-of-information">{displaySourceOfInformation || fillerIcon}</TableCell>
        <TableCell data-qa="selected-comp-actions">{actions || ''}</TableCell>
      </DraggableTableRow>
    )
  }

  renderTableBodyContent = () => {
    const { salesComps, removeComp, subjectProperty, subjectCoordinates, navigateToEditModal, unitOfComparison } =
      this.props
    const salesCompsTableRows = []
    const photoElement = (
      <div>
        <StarRounded />
      </div>
    )

    const { propertySummary } = subjectProperty
    const { streetAddress, city, state } = propertySummary
    const subjectAddress = `${streetAddress}, ${city}, ${invert(STATE_NAMES)[state]}`
    const subject = this.fillTableRowTemplate({
      ...subjectProperty,
      streetAddress: subjectAddress,
      photoElement,
      type: subjectProperty.type,
      gba: formatInt(subjectProperty.gba),
      distance: null,
    })

    salesCompsTableRows.push(subject)
    const salesCompsToRender = salesComps
      .filter(comp => !comp.isRemoved)
      .map(salesComp => {
        const { propertyInformation, address, saleInformation } = salesComp
        const salePrice = getSalePriceToUse(saleInformation)
        const basis = getBasis(unitOfComparison, propertyInformation)
        const salePricePerBasis = divide(salePrice, basis)
        return {
          ...salesComp,
          distance: calculateDistance(subjectCoordinates, address.coords),
          saleDate: saleInformation.saleDate,
          salePricePerBasis,
          basis,
          compId: salesComp._id,
          photoUrl: get(propertyInformation, 'photo.cdnUrl') || SALES_COMP_STOCK_PHOTO,
        }
      })

    forEach(salesCompsToRender, (salesComp, index) => {
      salesCompsTableRows.push(this.renderTableRow({ salesComp, index, removeComp, navigateToEditModal }))
    })
    return salesCompsTableRows
  }

  getScoreDisplay = ({ score }) => {
    if (!score?.total) {
      return ''
    }
    return `Total Score: ${Math.round(score.total)}${Object.keys(score).reduce((out, key) => {
      if (key === 'total') {
        return out
      }
      let title = key.replace(/([A-Z])/g, ' $1')
      title = title.charAt(0).toUpperCase() + title.slice(1)
      return `${out}\n${title}: ${Math.round(score[key])}`
    }, '')}`
  }

  renderTableRow = ({ salesComp, index, removeComp, navigateToEditModal }) => {
    const { unitOfComparison } = this.props
    const CustomDetailsButton = this.props.getCustomDetailsButton(salesComp)

    const actions = (
      <Stack alignItems="center" direction="row" spacing={1}>
        {CustomDetailsButton ? (
          <CustomDetailsButton />
        ) : (
          <Button variant="text" onClick={() => navigateToEditModal(salesComp.compId)} data-qa="details-btn">
            Details
          </Button>
        )}
        <Tooltip placement="top" title="Remove">
          <IconButton aria-label="Remove" data-qa="selected-comp-remove-btn" onClick={() => removeComp(salesComp)}>
            <RemoveCircle color="error" />
          </IconButton>
        </Tooltip>
      </Stack>
    )

    const sourceIcon = salesComp.isAutoComp ? (
      <Tooltip
        placement="top"
        title={
          <>
            This Land Comp was added automatically.
            <FeatureToggle featureFlag={FEATURE_FLAG_AUTO_SALES_COMPS_RULE_EDIT} checkForValue={true}>
              <div style={{ whiteSpace: 'pre-line' }}>{this.getScoreDisplay(salesComp)}</div>
            </FeatureToggle>
          </>
        }
      >
        <Stack color="#673AB7">
          <AutoModeIcon />
        </Stack>
      </Tooltip>
    ) : null

    const photoTooltip = (
      <Avatar
        alt="Land Comp"
        src={salesComp.photoUrl}
        sx={{ width: 100, height: 100, objectFit: 'contain' }}
        variant="square"
      />
    )

    const photoElement = (
      <Tooltip placement="left" title={photoTooltip}>
        <Avatar
          alt="Land Comp"
          src={salesComp.photoUrl}
          sx={{ width: 36, height: 36, objectFit: 'cover' }}
          variant="square"
        />
      </Tooltip>
    )

    const displaySourceOfInformation = getSourceOfInformation(salesComp)
    const { propertyInformation, address, saleInformation, status } = salesComp
    const { buildableArea, buildableUnits, zoning } = propertyInformation
    const salePrice = getSalePriceToUse(saleInformation)
    const basis = getBasis(unitOfComparison, propertyInformation)
    const pricePerBasis = divide(salePrice, basis)
    const basisCurrencyFormatter = getCurrencyFormatter(unitOfComparison)
    return this.fillTableRowTemplate({
      compId: salesComp.compId || index,
      compIndex: index,
      streetAddress: address.streetAddress,
      city: address.city,
      state: address.state,
      zip: address.postalCode,
      salePrice: formatCurrencyInt(salePrice),
      distance: `${salesComp.distance} mi`,
      photoElement,
      photoTooltip,
      buildableArea,
      buildableUnits,
      zoning,
      saleDate: saleInformation.saleDate,
      saleStatus: saleInformation.saleStatus,
      pricePerBasis: basisCurrencyFormatter(pricePerBasis),
      actions,
      sourceIcon,
      displaySourceOfInformation,
      isDrmComp: !!(salesComp.salesTransactionId && salesComp.salesTransactionVersion),
      isLatestVersion: salesComp.isLatestVersion,
      status: status,
      isDeleted: !!salesComp.deletedAt,
    })
  }

  onDragStart = event => {
    this.setState({ draggingId: event.draggableId })
  }

  moveCard = ({ source, destination }) => {
    const { form, salesComps } = this.props

    this.setState({ draggingId: '' })

    if (!destination) {
      return
    }

    if (destination.index === 0) {
      // trying to move a comp above property row
      return
    }

    const [comp] = salesComps.splice(source.index, 1)
    salesComps.splice(destination.index - 1, 0, comp)

    form.change('selectedComps', salesComps)
  }

  getExportInDropdownItems = () => {
    const {
      form: {
        values: { type },
      },
    } = this.props
    if (type === SALES_APPROACH_TYPES.LAND) {
      return EXPORT_IN_DROPDOWN_ITEMS.filter(item => item.value !== EXPORT_IN_VALUES.ADDENDA)
    }
    return EXPORT_IN_DROPDOWN_ITEMS
  }

  render() {
    const { title, sortBy, unitOfComparison } = this.props

    return (
      <Paper>
        <Template
          is={`
            'title tools'
            'table table'
            /auto  1fr
          `}
          gap={2}
        >
          <Area is="title">
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
          </Area>
          <Area is="tools" gap={2} placeContent="flex-end" placeItems="center">
            <DropDown
              data-qa="sortLandComps-input"
              disabled={false}
              fullWidth={false}
              items={SALES_COMPS_SORT_DROPDOWN_ITEMS}
              label="Sort"
              margin="none"
              name="sortBy"
            />
            <FeatureToggle featureFlag="land-outline-export-option">
              <DropDown
                data-qa="salesOutlineExportLocation-input"
                disabled={false}
                fullWidth={false}
                items={this.getExportInDropdownItems()}
                label="Export In"
                margin="none"
                name="exportLocation"
              />
            </FeatureToggle>
          </Area>
          <Area is="table">
            <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.moveCard}>
              <DroppableTable id="selected-land-comps-table" isDropDisabled={false}>
                <EnhancedTableHead sortBy={sortBy} unitOfComparison={unitOfComparison} />
                <TableBody>{this.renderTableBodyContent()}</TableBody>
              </DroppableTable>
            </DragDropContext>
          </Area>
        </Template>
      </Paper>
    )
  }
}

const LandCompsFieldArray = props => (
  <FieldArray isEqual={isEqual} name={props.fieldName} component={SelectedLandCompsTable} {...props} />
)

export default LandCompsFieldArray
