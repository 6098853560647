import React from 'react'
import PropTypes from 'prop-types'
import {
  TableCell,
  Paper,
  TableRow,
  Table,
  IconButton,
  TableHead,
  TableBody,
  Typography,
  TableFooter,
  Collapse,
  Button,
  TextField,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { get, isNumber, findIndex, toUpper, cloneDeep, sum, find, startCase, noop } from 'lodash'

import Edit from '@mui/icons-material/Edit'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Save from '@mui/icons-material/Save'
import DeleteOutline from '@mui/icons-material/DeleteOutline'
import Dash from '@mui/icons-material/Remove'

import { getCustomRowNames, getMissingCompAdjustments } from 'shared/helpers/salesApproach/salesCompAdjustments'

import {
  DEFAULT_ADJUSTMENT_ROWS,
  MARKET_ROWS,
  LOCATION_ROWS,
  UTILITY_ROWS,
  OTHER_ROWS,
  ADJUSTMENT_GROUPS,
  CUSTOM_ADJUSTMENT_GROUPS,
  OTHER_ADJUSTMENT_ROW_KEYS,
  OTHER_ADJUSTMENT_PATH,
} from 'shared/helpers/salesApproach/salesCompAdjustmentConstants'
import { getSalePriceToUse, getUnitOfComparisonText } from 'shared/report-calculations/sales-approach/helpers'

import { IncomeAdjustmentKeys } from 'shared/constants/report/sales/salesAdjustment'
import { PropertyTypes } from 'shared/constants'
import { SALES_APPROACH_TYPES } from 'shared/constants/salesApproach'

import { toPercents, fromPercents } from 'client-shared/utils/numberOperations'

import { formatCurrencyFloat, formatPercentageString, toPercentageString } from 'client-shared/utils/numberFormatters'

import { Number as NumberField } from '../../../../shared/components'
import { NOT_AVAILABLE } from '../../../constants'

import {
  getNoiAdjustment,
  getTotalAdjustedPercentages,
  getTrendAdjustedPricePerBasis,
  calculateTotalGroupAdjustments,
} from '../calculations'

import Tooltip from '../../../../shared/components/Tooltip'

import LightTooltip from './Components/LightTooltip'

import CompHeaderCell from './Components/CompHeaderCell'

import {
  ExpandedMarketRows,
  ExpandedLocationRows,
  ExpandedUtilityRows,
  ExpandedOtherRows,
  TOOLTIP_TEXT,
  EXPANSION_PANELS,
} from './constants'

import styles from './styles'
import ExpandedTable from './Components/ExpandedTable'
import ClearAdjustmentsModal from './Components/ClearAdjustmentsModal'

class SalesAdjustmentGrid extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    batch: PropTypes.func.isRequired,
    showEditModal: PropTypes.func.isRequired,
    registerField: PropTypes.func.isRequired,
  }
  state = {
    expandedMarket: false,
    expandedLocation: false,
    expandedUtility: false,
    expandedOther: false,
    toolTipText: null,
    open: false,
    expanded: false,
    isEditingOther: [],
    isEditingUtilities: [],
    rowInEditState: { rowName: null, groupName: null, updatedName: '' },
    erroredCells: [],
  }

  componentDidMount = () => {
    const { batch, change, formValues } = this.props
    const { compAdjustments, selectedComps, type: approachType, propertyType } = formValues

    batch(() => {
      const allCompAdjustments = getMissingCompAdjustments(selectedComps, compAdjustments, approachType, propertyType)
      if (allCompAdjustments.length) {
        change('compAdjustments', allCompAdjustments)
      }
    })
  }

  getCompAdjustments = compId => {
    const { formValues } = this.props
    const adjustments = get(formValues, 'compAdjustments', [])
    const compAdjustment = find(adjustments, adjustment => adjustment?.compId === compId.toString())

    if (compAdjustment) {
      return compAdjustment.adjustments
    }
    return []
  }

  updateCompAdjustments = (compId, updatedAdjustments) => {
    const { change, formValues } = this.props
    const adjustments = get(formValues, 'compAdjustments')
    const adjustmentIndex = findIndex(adjustments, adjustment => adjustment?.compId === compId)

    change(`compAdjustments.${adjustmentIndex}.adjustments`, cloneDeep(updatedAdjustments))
  }

  clearAdjustments = adjustments => {
    for (const [, adjustment] of adjustments.entries()) {
      adjustment.value = 0
    }
    return adjustments
  }

  clearComp = selectedComp => {
    const adjustments = this.getCompAdjustments(selectedComp._id)
    const clearedAdjustments = this.clearAdjustments(adjustments)
    this.updateCompAdjustments(selectedComp._id, clearedAdjustments)
  }

  getNewAdjustmentName = (rowNames, groupName) => {
    let defaultName
    switch (groupName) {
      case ADJUSTMENT_GROUPS.UTILITY:
        defaultName = `Other Utilities Adjustment`
        break
      default:
        defaultName = `Other Adjustment`
    }
    const rowsSet = new Set(rowNames)
    let index = 1
    let newName = defaultName
    while (rowsSet.has(newName)) {
      newName = `${defaultName} ${index}`
      index += 1
    }
    return newName
  }

  addCustomAdjustment = groupName => {
    const { change, formValues, batch, registerField } = this.props
    const { compAdjustments, otherAdjustmentDiscussions } = formValues

    const rowNamesInGroup = getCustomRowNames(compAdjustments, groupName)
    const addedRowName = this.getNewAdjustmentName(rowNamesInGroup, groupName)

    batch(() => {
      for (const [compAdjustmentIndex, compAdjustment] of compAdjustments.entries()) {
        const { adjustments } = compAdjustment
        adjustments.push({
          name: addedRowName,
          value: 0,
          groupName,
          custom: true,
        })
        const path = `compAdjustments[${compAdjustmentIndex}].adjustments`
        change(path, [...adjustments])
        registerField(`compAdjustments[${compAdjustmentIndex}].adjustments[${adjustments.length - 1}]`, noop, {})
      }

      // todo - this seems like it's adding a discussion for custom utility adjustments,
      //  which never gets displayed anywhere?
      const newAdjustmentDiscussion = { name: addedRowName, discussion: {} }
      otherAdjustmentDiscussions.push(newAdjustmentDiscussion)
      change('otherAdjustmentDiscussions', [...otherAdjustmentDiscussions])
      registerField(`otherAdjustmentDiscussions[${otherAdjustmentDiscussions.length - 1}]`, noop, {})
    })
  }

  deleteOtherAdjustment = (rowName, groupName) => {
    const { change, batch, formValues } = this.props
    const { compAdjustments, otherAdjustmentDiscussions } = formValues

    batch(() => {
      for (const [compAdjustmentIndex, compAdjustment] of compAdjustments.entries()) {
        const { adjustments } = compAdjustment
        const indexToRemove = findIndex(
          adjustments,
          adjustment => adjustment?.groupName === groupName && adjustment?.name === rowName
        )
        adjustments.splice(indexToRemove, 1)
        const path = `compAdjustments[${compAdjustmentIndex}].adjustments`
        change(path, [...adjustments])
      }
      const discussionIndex = findIndex(otherAdjustmentDiscussions, discussion => discussion.name === rowName)
      otherAdjustmentDiscussions?.splice(discussionIndex, 1)
      change('otherAdjustmentDiscussions', otherAdjustmentDiscussions)
    })
  }
  clearAllComps = () => {
    const { formValues, batch } = this.props
    const selectedComps = get(formValues, 'selectedComps', [])

    batch(() => {
      selectedComps.forEach(comp => {
        this.clearComp(comp)
      })
    })
  }

  togglePanel = name => event => {
    switch (name) {
      case EXPANSION_PANELS.market:
        this.setState({
          expandedMarket: !this.state.expandedMarket,
        })
        break
      case EXPANSION_PANELS.location:
        this.setState({
          expandedLocation: !this.state.expandedLocation,
        })
        break
      case EXPANSION_PANELS.utility:
        this.setState({
          expandedUtility: !this.state.expandedUtility,
        })
        break
      case EXPANSION_PANELS.other:
        this.setState({
          expandedOther: !this.state.expandedOther,
        })
        break
      default:
    }
  }

  getNetMarketAdjustment = comp => {
    const adjustments = this.getCompAdjustments(comp._id)
    return calculateTotalGroupAdjustments(adjustments, ADJUSTMENT_GROUPS.MARKET)
  }

  getTotalLocationAdjustment = comp => {
    const adjustments = this.getCompAdjustments(comp._id)
    return calculateTotalGroupAdjustments(adjustments, ADJUSTMENT_GROUPS.LOCATION)
  }

  getOtherAdjustmentValue = otherAdjustment => {
    if (Array.isArray(otherAdjustment)) {
      return sum(otherAdjustment)
    }
    return otherAdjustment
  }

  getTotalUtilityAdjustment = comp => {
    const adjustments = this.getCompAdjustments(comp._id)
    return calculateTotalGroupAdjustments(adjustments, ADJUSTMENT_GROUPS.UTILITY)
  }

  getTotalOtherAdjustment = comp => {
    const adjustments = comp.adjustments
    return (
      adjustments.sizeAdjustment +
      adjustments.stabilizationLevelAdjustment +
      adjustments.conditionAdjustment +
      sum(adjustments.otherAdjustment)
    )
  }

  getNetPropertyAdjustment = comp => {
    const { formValues } = this.props
    const { netOperatingIncome, temper, unitOfComparison, incomeAdjustmentLevel, subjectPropertyInformation } =
      formValues
    const adjustments = this.getCompAdjustments(comp._id)

    return getTotalAdjustedPercentages(
      comp,
      unitOfComparison,
      temper,
      netOperatingIncome.total,
      subjectPropertyInformation,
      incomeAdjustmentLevel,
      adjustments
    )
  }

  // unadjusted sale price per sf or unit,
  // plus the aggregate of net market adjustments applied to the unadjusted sale price per sf or unit
  getTrendedPrice = comp => {
    const { formValues } = this.props
    const { unitOfComparison } = formValues
    const adjustments = this.getCompAdjustments(comp._id)

    return getTrendAdjustedPricePerBasis(comp, adjustments, unitOfComparison)
  }

  // trended price, plus the percentage of net property adjustments applied to the trended price
  getAdjustedPrice = comp => {
    const netPropertyAdjustment = this.getNetPropertyAdjustment(comp)
    const trendedPrice = this.getTrendedPrice(comp)

    return trendedPrice + netPropertyAdjustment * trendedPrice
  }

  formatNonNullablePercents = value => {
    return fromPercents(value || 0)
  }
  validateInputValue = (value, allValue, meta) => {
    const valueAsPercent = toPercents(value)
    const name = meta.name
    const erroredCells = Array.from(this.state.erroredCells)
    const INVISIBLE_ERROR_STRING = ' '
    if (isNaN(valueAsPercent)) {
      if (!erroredCells.includes(name)) {
        erroredCells.push(name)
        this.setState({
          erroredCells,
        })
      }
      return INVISIBLE_ERROR_STRING
    } else {
      if (erroredCells.includes(name)) {
        const indexOf = findIndex(
          erroredCells,
          cell => {
            return cell === name
          },
          0
        )
        erroredCells.splice(indexOf, 1)
        this.setState({
          erroredCells,
        })
      }
      return undefined
    }
  }

  getCellsForRow = (rowName, selectedComps, adjustments) => {
    const { classes } = this.props
    const compAdjustmentLength = selectedComps.length
    const adjustmentsLength = adjustments.length
    return selectedComps.map(comp => {
      const cellClasses = [classes.masterTable_TableBody_TableCell]
      const compAdjustmentIndex = findIndex(adjustments, adjustment => adjustment?.compId === comp._id.toString())

      const adjustmentRowIndex = findIndex(
        get(adjustments[compAdjustmentIndex], 'adjustments', []),
        adjustmentRow => adjustmentRow.name === rowName
      )

      let name = `compAdjustments[${compAdjustmentIndex}].adjustments[${adjustmentRowIndex}].value`

      if (this.state.erroredCells.includes(name)) {
        cellClasses.push(classes.masterTable_TableBody_TableCell_Error)
      }
      if (compAdjustmentIndex < 0 || adjustmentRowIndex < 0) {
        // If the adjustment doesn't exist, add it
        name = `compAdjustments[${compAdjustmentLength}].adjustments[${adjustmentsLength}].value`
      }
      return (
        <TableCell padding="none" className={cellClasses} key={comp._id}>
          <div className={classes.masterTable_TableBody_TableCell_Container}>
            <NumberField
              allowNegative={true}
              bare
              classes={{ formControl: classes.numberField }}
              decimalScale={0}
              defaultValue={0}
              format={value => {
                if (value === '-') {
                  return '-'
                }
                return toPercents(value)
              }}
              name={name}
              noFocus
              normalize={this.formatNonNullablePercents}
              suffix="%"
              validate={this.validateInputValue}
              right
            />
          </div>
        </TableCell>
      )
    })
  }

  getIncomeLevelRow = () => {
    const { classes, formValues } = this.props
    const { selectedComps, unitOfComparison, temper, netOperatingIncome, subjectPropertyInformation } = formValues
    const noiAdjustments = selectedComps.map(selectedComp =>
      getNoiAdjustment(
        selectedComp.saleInformation.capRate,
        getSalePriceToUse(selectedComp.saleInformation),
        selectedComp.propertyInformation,
        unitOfComparison,
        temper,
        netOperatingIncome.total,
        subjectPropertyInformation
      )
    )
    return (
      <TableRow className={classes.masterTable_TableBody_Row}>
        <TableCell className={classes.masterTable_TableBody_TableCell_Label}>
          <LightTooltip text="Income Level Adjustment" placement="top">
            <div className={classes.masterTable_TableBody_TableCell_Label_TextContainer}>Income Level Adjustment</div>
          </LightTooltip>
        </TableCell>
        {selectedComps.map((comp, index) => (
          <TableCell
            padding="none"
            className={classes.masterTable_TableBody_TableCell_Disabled}
            disabled
            key={comp._id}
          >
            <div>{isNumber(noiAdjustments[index]) ? toPercentageString(noiAdjustments[index], 0) : NOT_AVAILABLE}</div>
          </TableCell>
        ))}
      </TableRow>
    )
  }

  getRentStabilizationRow = rowName => {
    const {
      classes,
      formValues: { propertyType, selectedComps, compAdjustments },
    } = this.props
    if (propertyType === PropertyTypes.COMMERCIAL) {
      return null
    }
    return (
      <TableRow className={classes.masterTable_TableBody_Row} key={rowName}>
        <TableCell className={classes.masterTable_TableBody_TableCell_Label}>
          <LightTooltip text="Rent Stabilization Level Adjustment" placement="top">
            <div className={classes.masterTable_TableBody_TableCell_Label_TextContainer}>
              Rent Stabilization Level Adjustment
            </div>
          </LightTooltip>
        </TableCell>
        {this.getCellsForRow(rowName, selectedComps, compAdjustments)}
      </TableRow>
    )
  }

  getDefaultUtilityRows = () => {
    const { formValues } = this.props
    if (formValues.propertyType in UTILITY_ROWS) {
      return UTILITY_ROWS[formValues.propertyType]
    }
    return UTILITY_ROWS['default']
  }

  getDetailsRow = (groupName, title, expanded, rows) => {
    const { classes, formValues, showEditModal } = this.props
    const colSpan = formValues.selectedComps.length + 1
    const tooltipText = TOOLTIP_TEXT[toUpper(groupName)]
    const hasAddAdjustment = CUSTOM_ADJUSTMENT_GROUPS.includes(groupName)
    const dataqaExpansionPanel = `expansion-row-${title.toLowerCase().replace(' ', '-')}`
    const handleClick = event => {
      event.stopPropagation()
      this.addCustomAdjustment(groupName)
    }

    return (
      <TableRow className={classes.masterTable_TableBody_Row_ExpansionPanel} data-qa={dataqaExpansionPanel}>
        <TableCell colSpan={colSpan} className={classes.expansionPanel} onClick={this.togglePanel(groupName)}>
          <div className={classes.expansionTitleContainer}>
            <div className={classes.expansionTitle}>
              <Typography color="primary" variant="body1" component="div">
                {title}
              </Typography>

              <Tooltip text={tooltipText} placement="top-start">
                <IconButton
                  className={classNames(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                  aria-expanded={expanded}
                  aria-label="Show more"
                >
                  <ExpandMore />
                </IconButton>
              </Tooltip>
            </div>
            {hasAddAdjustment && (
              <Button
                variant="text"
                className={classes.add_adjustment_button}
                onClick={handleClick}
                data-qa={OTHER_ADJUSTMENT_PATH[groupName].label}
              >
                Add adjustment
              </Button>
            )}
          </div>
          <Collapse in={expanded} unmountOnExit>
            <ExpandedTable rows={rows} showEditModal={showEditModal} formValues={formValues} />
          </Collapse>
        </TableCell>
      </TableRow>
    )
  }

  getDefaultRow = rowName => {
    const {
      classes,
      formValues: { selectedComps, compAdjustments },
    } = this.props
    const rowTitle = DEFAULT_ADJUSTMENT_ROWS.find(row => row.name === rowName).title
    return (
      <TableRow className={classes.masterTable_TableBody_Row} key={rowName} data-qa={rowTitle}>
        <TableCell className={classes.masterTable_TableBody_TableCell_Label}>
          <LightTooltip text={rowTitle} placement="top">
            <div className={classes.masterTable_TableBody_TableCell_Label_TextContainer}>{rowTitle}</div>
          </LightTooltip>
        </TableCell>
        {this.getCellsForRow(rowName, selectedComps, compAdjustments)}
      </TableRow>
    )
  }

  handleSaveEditedRow = () => {
    const {
      formValues: { compAdjustments, otherAdjustmentDiscussions },
      change,
      batch,
    } = this.props

    const {
      rowInEditState: { rowName, groupName, updatedName },
    } = this.state

    batch(() => {
      for (const [compAdjustmentIndex, compAdjustment] of compAdjustments.entries()) {
        const { adjustments } = compAdjustment
        const adjustmentIndex = findIndex(
          adjustments,
          adjustment => adjustment?.name === rowName && adjustment?.groupName === groupName
        )
        const path = `compAdjustments[${compAdjustmentIndex}].adjustments[${adjustmentIndex}].name`
        const discussionIndex = findIndex(otherAdjustmentDiscussions, discussion => discussion?.name === rowName)
        if (discussionIndex !== -1) {
          const discussionPath = `otherAdjustmentDiscussions[${discussionIndex}]`
          const updatedDiscussion = {
            ...otherAdjustmentDiscussions[discussionIndex],
            name: updatedName,
          }
          change(discussionPath, updatedDiscussion)
        }
        change(path, updatedName)
      }
      this.setState({
        rowInEditState: {
          rowName: null,
          groupName: null,
          updatedName: null,
        },
      })
    })
  }

  getCustomRow = (rowName, groupName) => {
    const { classes, formValues } = this.props
    const { selectedComps, compAdjustments } = formValues
    const { rowInEditState } = this.state

    const inEditState = rowInEditState.rowName === rowName && rowInEditState.groupName === groupName
    const disableActions = rowInEditState.rowName !== null && rowInEditState.groupName !== null

    const handleEditClick = () => {
      this.setState({
        rowInEditState: {
          rowName,
          groupName,
          updatedName: rowName,
        },
      })
    }

    const handleChange = event => {
      this.setState({
        rowInEditState: {
          rowName,
          groupName,
          updatedName: event.target.value,
        },
      })
    }
    return (
      <TableRow className={classes.masterTable_TableBody_Row} key={`${rowName}`}>
        {inEditState ? (
          <TableCell className={classes.masterTable_TableBody_TableCell_Label}>
            <div className={classes.masterTable_TableBody_Editing}>
              <TextField onChange={handleChange} margin="none" value={rowInEditState.updatedName} />
              <IconButton aria-label="edit" size="small" onClick={this.handleSaveEditedRow}>
                <Save color="action" />
              </IconButton>
            </div>
          </TableCell>
        ) : (
          <TableCell className={classes.masterTable_TableBody_TableCell_Label}>
            <LightTooltip text={rowName} placement="top">
              <div className={classes.masterTable_TableBody_Editable}>
                {rowName}
                <IconButton aria-label="edit" onClick={handleEditClick} disabled={disableActions}>
                  <Edit />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  disabled={disableActions}
                  onClick={() => this.deleteOtherAdjustment(rowName, groupName)}
                >
                  <DeleteOutline />
                </IconButton>
              </div>
            </LightTooltip>
          </TableCell>
        )}
        {this.getCellsForRow(rowName, selectedComps, compAdjustments)}
      </TableRow>
    )
  }

  render() {
    const { formValues, classes, showEditModal } = this.props
    const { selectedComps, incomeAdjustmentLevel, unitOfComparison, compAdjustments, type } = formValues
    const trendedPriceName = `Cumulative Price Per ${startCase(getUnitOfComparisonText(unitOfComparison))}`
    const adjustedPriceName = `Adjusted Price Per ${startCase(getUnitOfComparisonText(unitOfComparison))}`
    const { expandedLocation, expandedMarket, expandedUtility, expandedOther } = this.state
    const incomeLevel = incomeAdjustmentLevel === IncomeAdjustmentKeys.income
    const rentStabilizationLevel = incomeAdjustmentLevel === IncomeAdjustmentKeys.rentStabilization

    return (
      <Paper className={classes.paper_masterTable}>
        <Table className={classes.masterTable}>
          <TableHead className={classes.masterTable_Thead} data-qa="sales-adjustment-grid-header">
            <TableRow className={classes.masterTable_Header_TitleRow}>
              <TableCell width="85%" className={classes.masterTable_Header_Title_Cell}>
                <Typography variant="h5" className={classes.adjustmentGridTitle}>
                  Sales Adjustment Grid
                </Typography>
              </TableCell>
              <TableCell width="15%" className={classes.masterTable_Header_Clear_Cell}>
                <ClearAdjustmentsModal clearAllComps={this.clearAllComps} classes={classes} />
              </TableCell>
            </TableRow>
            <TableRow className={classes.masterTable_Header_Row} data-qa="sales-adjustment-grid-header-row">
              <TableCell className={classes.masterTable_Header_Cell}>
                <div className={classes.subject_name_header_text}>Subject</div>
              </TableCell>
              {selectedComps.map((comp, index) => {
                const address = get(comp, 'address.streetAddress', '[ADDRESS]')
                return (
                  <CompHeaderCell
                    classes={classes}
                    address={address}
                    comp={comp}
                    key={comp._id}
                    editComp={showEditModal}
                    clearComp={this.clearComp}
                  />
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody className={classes.masterTable_TableBody} data-qa="sales-adjustment-grid-body">
            {this.getDetailsRow(ADJUSTMENT_GROUPS.MARKET, 'Market Adjustment', expandedMarket, ExpandedMarketRows)}
            {MARKET_ROWS.map(rowName => {
              return this.getDefaultRow(rowName)
            })}
            {this.getDetailsRow(
              ADJUSTMENT_GROUPS.LOCATION,
              'Location Adjustment',
              expandedLocation,
              ExpandedLocationRows
            )}
            {LOCATION_ROWS.map(rowName => {
              return this.getDefaultRow(rowName)
            })}
            {type === SALES_APPROACH_TYPES.IMPROVED && [
              this.getDetailsRow(ADJUSTMENT_GROUPS.UTILITY, 'Utility Adjustment', expandedUtility, ExpandedUtilityRows),
              ...this.getDefaultUtilityRows().map(rowName => {
                return this.getDefaultRow(rowName)
              }),
              ...getCustomRowNames(compAdjustments, ADJUSTMENT_GROUPS.UTILITY).map(rowName => {
                return this.getCustomRow(rowName, ADJUSTMENT_GROUPS.UTILITY)
              }),
            ]}
            {this.getDetailsRow(ADJUSTMENT_GROUPS.OTHER, 'Other Adjustment', expandedOther, ExpandedOtherRows[type])}
            {OTHER_ROWS[type].map(rowName => {
              if (rowName === OTHER_ADJUSTMENT_ROW_KEYS.stabilization) {
                if (incomeLevel) {
                  return this.getIncomeLevelRow()
                } else if (rentStabilizationLevel) {
                  return this.getRentStabilizationRow(rowName)
                } else {
                  return null
                }
              } else {
                return this.getDefaultRow(rowName)
              }
            })}
            {getCustomRowNames(compAdjustments, ADJUSTMENT_GROUPS.OTHER).map(rowName => {
              return this.getCustomRow(rowName, ADJUSTMENT_GROUPS.OTHER)
            })}
          </TableBody>
          <TableFooter className={classes.masterTable_Footer} data-qa="sales-adjustment-grid-footer">
            <TableRow className={classes.masterTable_Footer_Row}>
              <TableCell padding="none" className={classes.masterTable_Footer_Cell}>
                <div className={classes.masterTable_Footer_Cell_Lead_Content}>Net Market Adjustments</div>
              </TableCell>
              {selectedComps.map(comp => {
                const netMarketAdjustment = this.getNetMarketAdjustment(comp)
                return (
                  <TableCell padding="none" className={classes.masterTable_Footer_Cell} key={comp._id}>
                    <div className={classes.masterTable_Footer_Cell_Content}>
                      {formatPercentageString(netMarketAdjustment, 0)}
                    </div>
                  </TableCell>
                )
              })}
            </TableRow>
            <TableRow className={classes.masterTable_Footer_Row}>
              <TableCell padding="none" className={classes.masterTable_Footer_Cell}>
                <div className={classes.masterTable_Footer_Cell_Lead_Content}>{trendedPriceName}</div>
              </TableCell>

              {selectedComps.map(comp => {
                const trendedPrice = this.getTrendedPrice(comp)
                const formattedTrendedPrice = Number.isFinite(trendedPrice) ? (
                  formatCurrencyFloat(trendedPrice)
                ) : (
                  <Dash />
                )
                return (
                  <TableCell padding="none" className={classes.masterTable_Footer_Cell} key={comp._id}>
                    <div className={classes.masterTable_Footer_Cell_Content}>{formattedTrendedPrice}</div>
                  </TableCell>
                )
              })}
            </TableRow>
            <TableRow className={classes.masterTable_Footer_Row}>
              <TableCell padding="none" className={classes.masterTable_Footer_Cell}>
                <div className={classes.masterTable_Footer_Cell_Lead_Content}> Total Location Adjustments</div>
              </TableCell>
              {selectedComps.map(comp => {
                const totalLocationAdjustment = this.getTotalLocationAdjustment(comp)
                return (
                  <TableCell padding="none" className={classes.masterTable_Footer_Cell} key={comp._id}>
                    <div className={classes.masterTable_Footer_Cell_Content}>
                      {formatPercentageString(totalLocationAdjustment, 2)}
                    </div>
                  </TableCell>
                )
              })}
            </TableRow>

            <TableRow className={classes.masterTable_Footer_Row}>
              <TableCell padding="none" className={classes.masterTable_Footer_Cell}>
                <div className={classes.masterTable_Footer_Cell_Lead_Content}>Total Utility Adjustments</div>
              </TableCell>
              {selectedComps.map(comp => {
                const totalUtilityAdjustment = this.getTotalUtilityAdjustment(comp)
                return (
                  <TableCell padding="none" className={classes.masterTable_Footer_Cell} key={comp._id}>
                    <div className={classes.masterTable_Footer_Cell_Content}>
                      {formatPercentageString(totalUtilityAdjustment, 2)}
                    </div>
                  </TableCell>
                )
              })}
            </TableRow>
            <TableRow className={classes.masterTable_Footer_Row}>
              <TableCell padding="none" className={classes.masterTable_Footer_Cell}>
                <div
                  className={classNames(
                    classes.masterTable_Footer_Cell_Lead_Content,
                    classes.masterTable_Footer_Cell_Total
                  )}
                >
                  Net Property Adjustments
                </div>
              </TableCell>
              {selectedComps.map(comp => {
                const netPropertyAdjustment = this.getNetPropertyAdjustment(comp)

                return (
                  <TableCell padding="none" className={classes.masterTable_Footer_Cell} key={comp._id}>
                    <div className={classes.masterTable_Footer_Cell_ContentTotal}>
                      {formatPercentageString(netPropertyAdjustment, 2)}
                    </div>
                  </TableCell>
                )
              })}
            </TableRow>
            <TableRow className={classes.masterTable_Footer_Row}>
              <TableCell padding="none" className={classes.masterTable_Footer_Cell}>
                <div
                  className={classNames(
                    classes.masterTable_Footer_Cell_Lead_Content,
                    classes.masterTable_Footer_Cell_Total
                  )}
                >
                  {adjustedPriceName}
                </div>
              </TableCell>
              {selectedComps.map(comp => {
                const adjustedPrice = this.getAdjustedPrice(comp)
                const formattedAdjustedPrice = Number.isFinite(adjustedPrice) ? (
                  formatCurrencyFloat(adjustedPrice)
                ) : (
                  <Dash />
                )
                return (
                  <TableCell padding="none" className={classes.masterTable_Footer_Cell} key={comp._id}>
                    <div className={classes.masterTable_Footer_Cell_ContentTotal}>{formattedAdjustedPrice}</div>
                  </TableCell>
                )
              })}
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    )
  }
}

export default withStyles(styles)(SalesAdjustmentGrid)
