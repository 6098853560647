export const MARKET_ADJUSTMENT_LABELS: { [key in string]: string } = {
  leaseTerms: 'Lease Terms Adjustment',
  dateSigned: 'Market Conditions Adjustment',
}

export const DEFAULT_PHYSICAL_ADJUSTMENT_LABELS: { [key in string]: string } = {
  size: 'Size Adjustment',
  locationBWNeighborhood: 'Location Adjustment',
  locationWithNeighborhood: 'Location Within Neighborhood Adjustment',
  frontage: 'Frontage Adjustment',
  ceilingHeight: 'Ceiling Height Adjustment',
  location: 'Corner Adjustment',
  condition: 'Condition Adjustment',
}

export const Labels = {
  COMMERCIAL_RENT_RECONCILIATION: 'Rent Reconciliation',
  RECONCILIATION: 'Reconciliation',
  MARKET_ADJUSTMENTS: 'Market Adjustments',
  MARKET_ADJUSTMENT: 'Market Conditions Adjustment',
  SKIP_THIS_GROUP_BECAUSE_THIS_GROUP_HAS_NOTHING_FOR_COMPARISON:
    'Skip this group because this group has nothing for comparison',
  COMP: 'Comp',
  BASE_UNIT: 'Base Unit',
  SUBJECT_UNIT: 'Subject Unit',
  TENANT_NAME: 'Tenant Name',
  RENT_PER_SF: 'Rent/SF',
  RENT_PER_SF_PER_MONTH: 'Rent/SF/Month',
  DATE_SIGNED: 'Date Signed',
  ADJUSTMENT: 'Adjustment',
  TRENDED_PRICE_PER_SF: 'Trended Rent/SF',
  TRENDED_PRICE_PER_SF_PER_MONTH: 'Trended Rent/SF/Month',
  SIZE_ADJUSTMENTS: 'Size Adjustments',
  SQUARE_FEET: 'Square feet',
  LOCATION_ADJUSTMENTS: 'Location Adjustments',
  BETTER_WORSE_NEIGHBORHOOD: 'Location',
  LOCATION_WITHIN_NEIGHBORHOOD: 'Location within Neighborhood',
  UTILITY_ADJUSTMENTS: 'Utility Adjustments',
  FRONTAGE: 'Frontage',
  CEILING_HEIGHT: 'Ceiling Height',
  CORNER: 'Corner',
  NA: '-',
  CONDITION_ADJUSTMENTS: 'Condition Adjustments',
  LEASE_TERMS_ADJUSTMENT: 'Lease Terms Adjustment',
  LEASE_TERMS_ADJUSTMENTS: 'Lease Terms Adjustments',
  LEASE_TERMS: 'Lease Terms',
  TOTALS_AND_CONCLUSION: 'Totals And Conclusions',
  TOTAL_ADJUSTMENT: 'Total Adjustment',
  ADJUSTED_RENT_PER_SF: 'Adjusted Rent/SF',
  ADJUSTED_RENT_PER_SF_PER_MONTH: 'Adjusted Rent/SF/Month',
  AVERAGE: 'Average',
  BASE_UNIT_IMPLIED_PER_SF: 'Base Unit Implied $/SF',
  BASE_UNIT_IMPLIED_PER_SF_PER_MONTH: 'Base Unit Implied $/SF/Month',
  MARKET_RENT_CONCLUSION: 'Market Rent Conclusion',
}

export const CALC_TYPES = [
  { label: '%', value: '%' },
  { label: '$/SF', value: '$/SF' },
]
