import React from 'react'
import PropTypes from 'prop-types'
import { TableRow } from '@material-ui/core'

import { Number, DropDown } from '../../../../../../shared/components'
import { toPercents, fromPercents } from '../../../../../../shared/utils/numberOperations'

import { Labels, CALC_TYPES } from '../CommercialRentReconciliationConstants'
import { Cell, TotalCell, BaseUnitCell, SubjectUnitCell, HeadingCell, TotalBaseUnitCell } from '../TableCells'

import { getRentPsfLabel } from '../../../../../../../../shared/helpers/incomeApproach/commercial'
import { formatCurrencyFloat } from '../../../../../../shared/utils/numberFormatters'
import { shortDateFormat } from '../../../../../../shared/utils/dateHelper'

import FieldCells from './components/FieldCells'

export default class MarketAdjustments extends React.PureComponent {
  static propTypes = {
    fieldPrefix: PropTypes.string.isRequired,
    baseUnitDateSigned: PropTypes.string,
    baseUnitLeaseTerms: PropTypes.string,
    units: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    rentComps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    leaseTermsCalcType: PropTypes.string.isRequired,
    baseUnitPerSFRent: PropTypes.number,
    rentRollRentBasis: PropTypes.string.isRequired,
    disableInputs: PropTypes.bool.isRequired,
  }

  get trendedRentPsfLabel() {
    const { rentRollRentBasis } = this.props
    return getRentPsfLabel(rentRollRentBasis, Labels.TRENDED_PRICE_PER_SF_PER_MONTH, Labels.TRENDED_PRICE_PER_SF)
  }

  formatLeaseTermsUnit = (index, type) => {
    const { leaseTermsCalcType, fieldPrefix, disableInputs } = this.props

    return leaseTermsCalcType === '$/SF' ? (
      <Number
        decimalScale={2}
        name={`${fieldPrefix}.${type}[${index}].adjustments.leaseTerms`}
        prefix="$"
        adornment={leaseTermsCalcType}
        disabled={disableInputs}
        format={value => `${formatCurrencyFloat(value, 2)}`}
      />
    ) : (
      <Number
        decimalScale={2}
        name={`${fieldPrefix}.${type}[${index}].adjustments.leaseTerms`}
        adornment={leaseTermsCalcType}
        format={toPercents}
        normalize={fromPercents}
        disabled={disableInputs}
      />
    )
  }

  render() {
    const { fieldPrefix, baseUnitDateSigned, baseUnitLeaseTerms, units, rentComps, disableInputs } = this.props

    return (
      <React.Fragment>
        <TableRow>
          <HeadingCell>{Labels.MARKET_ADJUSTMENTS}</HeadingCell>
        </TableRow>
        <TableRow data-qa="date-signed-row">
          <Cell data-qa="row-label-cell">{Labels.DATE_SIGNED}</Cell>
          <BaseUnitCell data-qa="base-unit-cell">{shortDateFormat(baseUnitDateSigned)}</BaseUnitCell>
          <FieldCells units={units} rentComps={rentComps} fieldType="dateSigned" cellType="date" />
        </TableRow>
        <TableRow data-qa="lease-terms-row">
          <Cell data-qa="row-label-cell">{Labels.LEASE_TERMS}</Cell>
          <BaseUnitCell data-qa="base-unit-cell">{baseUnitLeaseTerms || Labels.NA}</BaseUnitCell>
          <FieldCells units={units} rentComps={rentComps} fieldType="leaseTerms" />
        </TableRow>
        <TableRow data-qa="lease-terms-adjustments-row">
          <TotalCell data-qa="row-label-cell">{Labels.LEASE_TERMS_ADJUSTMENT}</TotalCell>
          <TotalBaseUnitCell data-qa="base-unit-cell">
            <div style={{ fontWeight: 400 }}>
              <DropDown name={`${fieldPrefix}.leaseTermsCalcType`} label="Calculations" items={CALC_TYPES} />
            </div>
          </TotalBaseUnitCell>
          {units.map((unit, index) => (
            <SubjectUnitCell data-qa={`subject-unit-${index}-cell`} key={unit._id}>
              {this.formatLeaseTermsUnit(index, 'commercialUnits')}
            </SubjectUnitCell>
          ))}
          {rentComps.map((rentComp, index) => (
            <Cell data-qa={`rent-comp-${index}-cell`} key={rentComp._id}>
              {this.formatLeaseTermsUnit(index, 'rentComps')}
            </Cell>
          ))}
        </TableRow>
        <TableRow data-qa="trended-price-per-sf-row">
          <Cell data-qa="row-label-cell">Lease Terms Adjustment Sub-Total</Cell>
          <BaseUnitCell data-qa="base-unit-cell" />
          <FieldCells
            units={units}
            rentComps={rentComps}
            fieldType="leaseTermsAdjustmentSubTotal"
            cellType="currency"
          />
        </TableRow>
        <TableRow data-qa="market-adjustments-row">
          <TotalCell data-qa="row-label-cell">{Labels.MARKET_ADJUSTMENT}</TotalCell>
          <BaseUnitCell data-qa="base-unit-cell" />
          {units.map((unit, index) => (
            <SubjectUnitCell data-qa={`subject-unit-${index}-cell`} key={unit._id}>
              <Number
                name={`${fieldPrefix}.commercialUnits[${index}].adjustments.dateSigned`}
                decimalScale={2}
                adornment="%"
                format={toPercents}
                normalize={fromPercents}
                disabled={disableInputs}
              />
            </SubjectUnitCell>
          ))}
          {rentComps.map((rentComp, index) => (
            <Cell data-qa={`rent-comp-${index}-cell`} key={rentComp._id}>
              <Number
                name={`${fieldPrefix}.rentComps[${index}].adjustments.dateSigned`}
                decimalScale={2}
                adornment="%"
                format={toPercents}
                normalize={fromPercents}
                disabled={disableInputs}
              />
            </Cell>
          ))}
        </TableRow>
        <TableRow data-qa="trended-price-per-sf-row">
          <Cell data-qa="row-label-cell">{this.trendedRentPsfLabel}</Cell>
          <BaseUnitCell data-qa="base-unit-cell" />
          <FieldCells units={units} rentComps={rentComps} fieldType="dateSignedTrendedPricePerSF" cellType="currency" />
        </TableRow>
      </React.Fragment>
    )
  }
}
