import { GeneratedText } from 'shared/utils/narrative/GeneratedText'

const generate = () => {
  const gt = new GeneratedText()

  gt.t`Adjustments for the comparable units have been considered based on comparison to the subject for market, 
  size, location, utility, condition, and lease terms. 
  All adjustments are percentages. A positive adjustment indicates an inferior characteristic to the subject. 
  A negative adjustment indicates a superior characteristic to the subject.`

  return gt.getParts()
}

export default { generate }
