import { LeaseStatusTypes } from '../../../../../../../shared/constants/report/incomeApproach/residential'

import { UNIT_LAYOUTS, OUTDOOR_SPACES, RENT_TYPES } from '../../../../constants'

import {
  currencyIntReadOnly,
  currencyFloatRight,
  formatIntRight,
  currencyParsedFloat,
  formatIntReadOnly,
  dropdownCellRenderer,
} from '../../../../../shared/components/TableHelpers/cellRenderers'
import { defaultRenderer } from '../../../../../shared/components/_mui5/Table/helpers'

import { getRentPSFLabel } from '../../../../../../../shared/helpers/rentRoll'

import {
  bathroomCountRenderer,
  rentPerSquareFootRenderer,
  formatPerSquareFootageFloatRight,
  deleteActionRenderer,
} from './rentRollRenderers'

import { LEASE_STATUS_OPTIONS, Labels } from './constants'

export const getTotals = units => {
  const rentTotals = getRentTotals(units)
  const forecastTotals = getForecastTotals(units)
  return {
    ...rentTotals,
    ...forecastTotals,
  }
}

const getRentTotals = units => {
  const rentTotalMonthly = units.reduce((total, unit) => {
    if (unit.leaseStatus !== LeaseStatusTypes.VACANT) {
      return total + (unit.rent || 0)
    } else {
      return total
    }
  }, 0)
  const rentTotalAnnual = rentTotalMonthly * 12
  return { rentTotalMonthly, rentTotalAnnual }
}

const getForecastTotals = units => {
  const forecastTotalMonthly = units.reduce((total, unit) => {
    if (unit.rentForecast) {
      return total + parseFloat(unit.rentForecast)
    } else {
      return total
    }
  }, 0)
  const forecastTotalAnnual = forecastTotalMonthly * 12
  return { forecastTotalMonthly, forecastTotalAnnual }
}

export const getUnitsTableColumns = (
  perUnitSF,
  bathrooms,
  outdoorSpace,
  unitLayout,
  showDevelopersForecast,
  includePerRoomAnalysis,
  unitRentPSFTimePeriod,
  onRowDeleteClick
) => {
  const columns = [
    {
      data: '#',
      value: '#',
      width: 58,
      readOnly: true,
      className: 'readOnly',
      type: 'numeric',
      renderer: formatIntReadOnly,
    },
    {
      data: 'isInspected',
      value: 'Inspected',
      width: 70,
      type: 'checkbox',
      className: 'alignCenter',
    },
    {
      data: 'number',
      value: Labels.UNIT_NUMBER,
      width: 110,
      className: 'alignRight',
      type: 'text',
      renderer: defaultRenderer,
    },
  ]

  if (includePerRoomAnalysis) {
    columns.push({
      data: 'rooms',
      value: Labels.ROOMS,
      width: 115,
      className: 'alignRight',
      type: 'numeric',
      renderer: formatIntRight,
    })
  }

  columns.push({
    data: 'bedrooms',
    value: `# ${Labels.BEDROOMS}`,
    width: 115,
    className: 'alignRight',
    type: 'numeric',
    renderer: formatIntRight,
  })

  if (unitLayout) {
    columns.push({
      data: 'unitLayout',
      value: Labels.UNIT_LAYOUT,
      width: 190,
      type: 'autocomplete',
      filter: false,
      source: UNIT_LAYOUTS,
      allowInvalid: false,
      strict: true,
      className: 'alignLeft',
      renderer: dropdownCellRenderer,
    })
  }

  if (bathrooms) {
    columns.push({
      data: 'bathrooms',
      value: `# ${Labels.BATHROOMS}`,
      width: 115,
      type: 'numeric',
      className: 'alignRight',
      renderer: bathroomCountRenderer,
    })
  }

  if (perUnitSF) {
    columns.push({
      data: 'squareFootage',
      value: Labels.SQUARE_FOOTAGE,
      width: 115,
      className: 'alignRight',
      type: 'numeric',
      renderer: formatPerSquareFootageFloatRight,
    })
  }

  if (outdoorSpace) {
    columns.push({
      data: 'outdoor',
      value: Labels.OUTDOOR_SPACE,
      width: 190,
      className: 'alignLeft',
      type: 'autocomplete',
      filter: false,
      source: OUTDOOR_SPACES,
      strict: true,
      allowInvalid: false,
      renderer: dropdownCellRenderer,
    })
  }

  columns.push(
    {
      data: 'rentType',
      value: Labels.RENT_TYPE,
      width: 190,
      className: 'alignLeft',
      type: 'autocomplete',
      filter: false,
      source: RENT_TYPES,
      strict: true,
      allowInvalid: false,
      renderer: dropdownCellRenderer,
    },
    {
      data: 'leaseStatus',
      value: Labels.LEASE_STATUS,
      width: 180,
      className: 'alignLeft',
      type: 'autocomplete',
      filter: false,
      source: LEASE_STATUS_OPTIONS,
      strict: true,
      allowInvalid: false,
      renderer: dropdownCellRenderer,
    },
    {
      data: 'leaseStartDate',
      value: 'Lease Start Date',
      className: 'alignRight',
      type: 'bowery.date',
      width: 150,
    },
    {
      data: 'leaseExpiryDate',
      value: 'Lease Expiration Date',
      className: 'alignRight',
      type: 'bowery.expirationDate',
      width: 150,
    },
    {
      data: 'rent',
      value: `${Labels.MONTHLY_RENT} ($)`,
      width: 140,
      type: 'numeric',
      className: 'alignRight',
      renderer: currencyFloatRight,
    }
  )

  if (includePerRoomAnalysis) {
    columns.push({
      data: 'rent/room',
      value: Labels.RENT_PER_ROOM,
      width: 115,
      className: 'readOnly',
      readOnly: true,
      type: 'numeric',
      renderer: currencyIntReadOnly,
    })
  }
  if (perUnitSF) {
    columns.push({
      data: 'rent/SF',
      value: getRentPSFLabel(unitRentPSFTimePeriod),
      width: 115,
      readOnly: true,
      className: 'readOnly',
      renderer: rentPerSquareFootRenderer,
    })
  }

  if (showDevelopersForecast) {
    columns.push({
      data: 'rentForecast',
      value: Labels.RENT_FORECAST,
      width: 140,
      type: 'numeric',
      className: 'alignRight',
      renderer: currencyParsedFloat,
    })
  }

  columns.push({
    value: Labels.ACTION,
    readOnly: true,
    width: 80,
    renderer: (instance, td, row, col, prop, value, cellProperties) => {
      deleteActionRenderer(instance, td, row, col, prop, value, cellProperties, onRowDeleteClick)
    },
  })

  return columns
}
