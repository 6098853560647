import { filter, find, sumBy, some, findIndex, startCase, get, capitalize, isNil } from 'lodash'

import BoweryDate from '@bowery-valuation/bowery-date'

import { formatCurrencyInt, formatInt, formatPercentageString } from 'shared/utils/formatters/numberFormatters'
import { formatList } from 'shared/utils/textHelpers'

import { BEDROOM_TYPES } from 'shared/constants/incomeApproach'
import { divide } from 'shared/utils/numberOperations'
import { getParkingRatio } from 'shared/utils/report/salesApproach'

import { VALUE_CONCLUSION_TYPES } from 'shared/constants/acas'

import {
  ADDITIONAL_COMP_INFO_ROW_IDS,
  getDefaultAdjustmentRows,
  DEFAULT_COMP_INFO_ROW_IDS,
  PROPERTY_TYPE_OPTIONS,
  SALE_CONDITIONS_OPTIONS,
  SITE_CONDITION_OPTIONS,
} from './salesCompAdjustmentConstants'

export const getAdjustment = (adjustments, name) => {
  return find(adjustments, adjustment => adjustment?.name === name)
}

export const getAdjustmentValue = (adjustments, name) => {
  const adjustment = getAdjustment(adjustments, name)
  return adjustment && adjustment?.value ? adjustment?.value : 0
}

export const getAdjustmentGroup = (adjustments, groupName) => {
  return filter(adjustments, adjustment => adjustment?.groupName === groupName)
}

export const getTotalUtilityAdjustments = adjustments => {
  const allUtilityAdjustments = getAdjustmentGroup(adjustments, 'utility')
  return sumBy(allUtilityAdjustments, 'value')
}

export const getCustomRowNames = (compAdjustments, groupName) => {
  if (!compAdjustments || compAdjustments?.length === 0) {
    return []
  }
  const customRowNames = new Set()
  for (const [, compAdjustment] of compAdjustments.entries()) {
    const { adjustments } = compAdjustment
    adjustments.forEach(adjustment => {
      if (adjustment.custom === true && adjustment.groupName === groupName) {
        customRowNames.add(adjustment.name)
      }
    })
  }
  return Array.from(customRowNames)
}

export const getAllCustomAdjustments = compAdjustments => {
  if (!compAdjustments || compAdjustments?.length === 0) {
    return []
  }
  const customAdjustments = new Map()
  for (const [, compAdjustment] of compAdjustments.entries()) {
    const { adjustments } = compAdjustment
    adjustments.forEach(adjustment => {
      if (adjustment.custom === true) {
        customAdjustments.set(adjustment.name, { ...adjustment, value: 0 })
      }
    })
  }
  return Array.from(customAdjustments, ([, value]) => value)
}

export const hasAdjustments = (compAdjustments, name, group) => {
  if (!compAdjustments || compAdjustments.length === 0) {
    return false
  }
  const adjustments = compAdjustments.reduce((collection, compAdjustment) => {
    const adjustmentValue = getAdjustmentValue(getAdjustmentGroup(compAdjustment.adjustments, group), name)
    collection.push(adjustmentValue)
    return collection
  }, [])
  return some(adjustments, value => !!value)
}

export const containsAdjustmentName = (compAdjustments, name) => {
  if (!compAdjustments || compAdjustments.length === 0) {
    return false
  }
  return !!find(compAdjustments[0].adjustments, adjustment => adjustment.name === name)
}

export const getMissingCompAdjustments = (selectedComps, compAdjustments, approachType, propertyType) => {
  const defaultAdjustments = getDefaultAdjustmentRows(approachType, propertyType)
  const mappedDefaultAdjustments = defaultAdjustments.map(row => ({
    name: row.name || '',
    value: 0,
    groupName: row.groupName,
    custom: false,
  }))

  const customAdjustments = getAllCustomAdjustments(compAdjustments)

  const allPotentialAdjustments = [...mappedDefaultAdjustments, ...customAdjustments]

  const allCompAdjustments = []
  for (const [, comp] of selectedComps.entries()) {
    const compAdjustment = compAdjustments.find(adj => adj.compId.toString() === comp._id.toString())
    if (compAdjustment) {
      // check adjustments compared to all adjustments
      const existingAdjustments = compAdjustment.adjustments
      if (existingAdjustments.length !== allPotentialAdjustments.length) {
        allPotentialAdjustments.forEach((potentialAdjustment, index) => {
          const foundAdjustment = find(
            existingAdjustments,
            existingAdjustment => existingAdjustment.name === potentialAdjustment.name
          )
          if (!foundAdjustment) {
            existingAdjustments.splice(index, 0, potentialAdjustment)
          }
        })
      }
      allCompAdjustments.push({
        compId: compAdjustment.compId,
        adjustments: existingAdjustments,
      })
    } else {
      allCompAdjustments.push({
        compId: comp._id,
        adjustments: allPotentialAdjustments,
      })
    }
  }
  return allCompAdjustments
}

export const getMissingCustomAdjustmentDiscussions = (otherAdjustmentDiscussions, compAdjustments) => {
  const customAdjustments = getAllCustomAdjustments(compAdjustments)

  const allOtherAdjustmentDiscussions = []
  customAdjustments.forEach(adjustment => {
    const { name } = adjustment
    const index = findIndex(otherAdjustmentDiscussions, discussion => discussion?.name === name)
    if (index === -1) {
      allOtherAdjustmentDiscussions.push({
        name,
        discussion: '',
      })
    } else {
      allOtherAdjustmentDiscussions.push(otherAdjustmentDiscussions[index])
    }
  })
  return allOtherAdjustmentDiscussions
}

export const getCompInfoRowFormatter = rowId => {
  let formatter = null

  switch (rowId) {
    case DEFAULT_COMP_INFO_ROW_IDS.saleDate:
      formatter = date => new BoweryDate(date).formatShortDate()
      break

    case DEFAULT_COMP_INFO_ROW_IDS.adjustedSalePrice:
    case ADDITIONAL_COMP_INFO_ROW_IDS.salePriceAdjustment:
    case ADDITIONAL_COMP_INFO_ROW_IDS.pricePerUnit:
    case ADDITIONAL_COMP_INFO_ROW_IDS.pricePerSF:
      formatter = formatCurrencyInt
      break

    case DEFAULT_COMP_INFO_ROW_IDS.propertyRights:
    case DEFAULT_COMP_INFO_ROW_IDS.condition:
    case DEFAULT_COMP_INFO_ROW_IDS.propertyType:
    case DEFAULT_COMP_INFO_ROW_IDS.buildingType:
    case ADDITIONAL_COMP_INFO_ROW_IDS.saleStatus:
      formatter = startCase
      break

    case DEFAULT_COMP_INFO_ROW_IDS.averageUnitSize:
    case DEFAULT_COMP_INFO_ROW_IDS.grossBuildingArea:
    case ADDITIONAL_COMP_INFO_ROW_IDS.siteArea:
    case ADDITIONAL_COMP_INFO_ROW_IDS.commercialArea:
    case ADDITIONAL_COMP_INFO_ROW_IDS.buildableArea:
    case ADDITIONAL_COMP_INFO_ROW_IDS.netRentableArea:
      formatter = formatInt
      break

    case DEFAULT_COMP_INFO_ROW_IDS.buildingAmenities:
      formatter = amenities => {
        const formattedAmenities = amenities.map(amenity => startCase(amenity))
        return formatList(formattedAmenities)
      }
      break

    case DEFAULT_COMP_INFO_ROW_IDS.occupancy:
      formatter = occupancy => (occupancy ? `${occupancy}%` : '')
      break

    case ADDITIONAL_COMP_INFO_ROW_IDS.saleCondition:
      formatter = saleCondition => SALE_CONDITIONS_OPTIONS.find(({ value }) => value === saleCondition)?.label
      break

    case ADDITIONAL_COMP_INFO_ROW_IDS.useType:
      formatter = useType => PROPERTY_TYPE_OPTIONS.find(({ value }) => value === useType)?.label
      break

    case ADDITIONAL_COMP_INFO_ROW_IDS.siteCondition:
      formatter = siteCondition => SITE_CONDITION_OPTIONS.find(({ value }) => value === siteCondition)?.label
      break

    case DEFAULT_COMP_INFO_ROW_IDS.capRate:
      formatter = formatPercentageString
      break

    case ADDITIONAL_COMP_INFO_ROW_IDS.unitTypes:
      formatter = unitTypes => {
        const formattedUnitTypes = unitTypes
          // eslint-disable-next-line eqeqeq
          .map(unitType => BEDROOM_TYPES.find(({ value }) => value == unitType)?.label)
          .filter(Boolean)
        return formatList(formattedUnitTypes)
      }
      break

    default:
      break
  }

  return formatter ? { formatter } : {}
}

export const getCompInfoSubjectValue = (rowId, subjectPropertyInformation, unitOfComparison) => {
  let subject = null

  switch (rowId) {
    case DEFAULT_COMP_INFO_ROW_IDS.city:
    case DEFAULT_COMP_INFO_ROW_IDS.market:
    case DEFAULT_COMP_INFO_ROW_IDS.submarket:
    case DEFAULT_COMP_INFO_ROW_IDS.residentialUnits:
    case DEFAULT_COMP_INFO_ROW_IDS.commercialUnits:
    case DEFAULT_COMP_INFO_ROW_IDS.condition:
    case DEFAULT_COMP_INFO_ROW_IDS.yearBuilt:
    case DEFAULT_COMP_INFO_ROW_IDS.parking:
    case DEFAULT_COMP_INFO_ROW_IDS.propertyType:
    case DEFAULT_COMP_INFO_ROW_IDS.buildingType:
    case DEFAULT_COMP_INFO_ROW_IDS.averageUnitSize:
    case DEFAULT_COMP_INFO_ROW_IDS.grossBuildingArea:
    case DEFAULT_COMP_INFO_ROW_IDS.propertyRights:
    case ADDITIONAL_COMP_INFO_ROW_IDS.numberOfFloors:
    case ADDITIONAL_COMP_INFO_ROW_IDS.zip:
    case ADDITIONAL_COMP_INFO_ROW_IDS.state:
    case ADDITIONAL_COMP_INFO_ROW_IDS.county:
    case ADDITIONAL_COMP_INFO_ROW_IDS.propertyId:
    case ADDITIONAL_COMP_INFO_ROW_IDS.censusTract:
    case ADDITIONAL_COMP_INFO_ROW_IDS.propertyName:
    case ADDITIONAL_COMP_INFO_ROW_IDS.neighborhood:
    case ADDITIONAL_COMP_INFO_ROW_IDS.commercialArea:
    case ADDITIONAL_COMP_INFO_ROW_IDS.buildingClass:
    case ADDITIONAL_COMP_INFO_ROW_IDS.buildableUnits:
    case ADDITIONAL_COMP_INFO_ROW_IDS.buildableArea:
    case ADDITIONAL_COMP_INFO_ROW_IDS.floorAreaRatio:
      subject = subjectPropertyInformation[rowId]
      break

    case DEFAULT_COMP_INFO_ROW_IDS.saleDate:
    case DEFAULT_COMP_INFO_ROW_IDS.adjustedSalePrice:
    case DEFAULT_COMP_INFO_ROW_IDS.capRate:
      subject = ''
      break

    case DEFAULT_COMP_INFO_ROW_IDS.parkingRatio:
      subject = getParkingRatio(subjectPropertyInformation, unitOfComparison)
      break

    default:
      break
  }

  if (subject == null) {
    return {}
  }

  let formattedSubjectValue
  switch (rowId) {
    case DEFAULT_COMP_INFO_ROW_IDS.propertyType:
    case DEFAULT_COMP_INFO_ROW_IDS.buildingType:
      formattedSubjectValue = startCase(subject)
      break

    case DEFAULT_COMP_INFO_ROW_IDS.condition:
      formattedSubjectValue = capitalize(subject)
      break

    case DEFAULT_COMP_INFO_ROW_IDS.averageUnitSize:
    case DEFAULT_COMP_INFO_ROW_IDS.grossBuildingArea:
    case ADDITIONAL_COMP_INFO_ROW_IDS.commercialArea:
      formattedSubjectValue = formatInt(subject)
      break

    default:
      formattedSubjectValue = subject
      break
  }

  return { subject: formattedSubjectValue }
}

export const calculatePricePerSF = (adjustedSalePrice, deedSalePrice, gba) => {
  if (isNil(gba)) {
    return ''
  }
  if (adjustedSalePrice) {
    return adjustedSalePrice / gba
  }
  if (deedSalePrice) {
    return deedSalePrice / gba
  }
  return ''
}

export const calculatePricePerUnit = (adjustedSalePrice, deedSalePrice, residentialUnits, commercialUnits) => {
  if (adjustedSalePrice) {
    return adjustedSalePrice / ((residentialUnits ?? 0) + (commercialUnits ?? 0))
  }
  if (deedSalePrice) {
    return deedSalePrice / ((residentialUnits ?? 0) + (commercialUnits ?? 0))
  }
  return ''
}

export const getMappedSubjectPropertyInformation = report => {
  const siteArea = get(report, 'property_information.lotArea', 0)
  const floorAreaRatio = get(report, 'zoning.floor_area_ratio', 0)

  const numberOfResidentialUnits = get(report, 'property_information.resUnits')
  const grossBuildingArea = get(report, 'property_information.grossBuildingArea')

  let propertyRights = ''
  let condition = ''
  const asStabilizedCondition = get(report, 'buildingDescription.generalAsStabilizedCondition')
  const asIsCondition = get(report, 'buildingDescription.generalCondition')
  switch (report.valueConclusionType) {
    case VALUE_CONCLUSION_TYPES.AS_STABILIZED:
      propertyRights = get(report, 'info.interestAppraisedAsStabilized')
      condition = asIsCondition?.other || asIsCondition?.value
      break
    case VALUE_CONCLUSION_TYPES.AS_COMPLETE:
      propertyRights = get(report, 'info.interestAppraisedAsStabilized')
      condition = asStabilizedCondition?.other || asStabilizedCondition?.value
      break
    default:
      propertyRights = get(report, 'info.interestAppraisedAsIsMarketValue')
      condition = asIsCondition?.other || asIsCondition?.value
  }

  const residentialUnits = get(report, 'residential.units', [])
  const unitsWithSqft = residentialUnits.filter(unit => unit.sqft)
  const residentialGrossBuildingArea = sumBy(unitsWithSqft, 'sqft') || 0

  const grossLeasableArea = get(report, 'residential.grossLeasableArea', 0)
  const averageUnitSize = residentialGrossBuildingArea
    ? divide(residentialGrossBuildingArea, unitsWithSqft.length)
    : divide(grossLeasableArea, numberOfResidentialUnits)
  return {
    grossBuildingArea,
    residentialUnits: numberOfResidentialUnits,
    commercialUnits: get(report, 'property_information.comUnits'),
    yearBuilt: get(report, 'property_information.yearBuilt'),
    siteArea: get(report, 'property_information.lotArea'),
    siteAreaUnit: get(report, 'property_information.siteAreaMeasure'),
    buildableUnits: get(report, 'zoning.buildableUnits', 0),
    buildableArea: siteArea * floorAreaRatio || 0,
    state: get(report, 'property_information.state'),
    city: get(report, 'property_information.city'),
    submarket: get(report, 'property_information.submarket'),
    propertyType: get(report, 'property_information.type'),
    market: get(report, 'property_information.macroMarket'),
    dateOfValue: get(report, 'inspection.dateOfValuation'),
    condition,
    averageUnitSize,
    buildingType: get(report, 'property_information.elevator', false) ? 'Elevator' : 'Walk Up',
    parking: get(report, 'potential_gross_income.parkingIncome.parkingSpaces', 0),
    propertyRights,
    numberOfFloors: get(report, 'property_information.floors'),
    zip: get(report, 'property_information.zip'),
    county: get(report, 'property_information.county'),
    propertyId: get(report, 'property_information.propertyIdentifier'),
    censusTract: get(report, 'property_information.censusTract'),
    propertyName: get(report, 'property_information.buildingName'),
    neighborhood: get(report, 'property_information.neighborhood'),
    commercialArea: get(report, 'property_information.commercialArea'),
    buildingClass: get(report, 'property_information.class'),
    floorAreaRatio: get(report, 'zoning.floor_area_ratio'),
  }
}
