import React, { useMemo, useCallback } from 'react'
import { get, noop, replace } from 'lodash'
import { Paper, Grid, Typography, Button } from '@mui/material'
import { withStyles } from '@material-ui/core/styles'
import { ColumnDataTypeEnum, RowBasedTable } from '@bowery-valuation/ui-components'

import { RowBasedTableColumn, getCustomColumnConfig } from 'client-shared/utils/rowBasedTable'
import { Checkbox, DropDown, Number } from 'client-shared/components'

import { formatCurrencyFloat, formatPercentageString } from 'shared/utils/formatters/numberFormatters'
import { LeaseTermsOptions } from 'shared/constants/report/incomeApproach/commercial'

import { fromPercents } from 'client-shared/utils/numberOperations'
import { required } from 'client-shared/utils/validation'

import AlertInfo from '../../../../../shared/components/AlertInfo'
import { CheckboxWithLabel } from '../../../../../shared/components'

import { getRentPsfLabel } from '../../../../../../../shared/helpers/incomeApproach/commercial'

import {
  getCompInformationRows,
  getNewAdjustmentName,
  getMarketRows,
  getPhysicalRows,
} from './helpers/reconciliation-group-table'
import { REMOVABLE_PHYSICAL_ROWS } from './CommercialRentReconciliationItemV2Constants'

import { Labels, CALC_TYPES } from './CommercialRentReconciliationConstants'

const styles = (theme: any) => ({
  paper: theme.paper,
  checkboxRoot: {
    display: 'inline-flex',
    width: 'initial',
    height: 32,
    marginLeft: theme.spacing.unit * 2,
  },
  checkbox: {
    paddingRight: 4,
  },
  dropdown: {
    width: 200,
  },
  numberField: {
    width: 200,
  },
})

type CommercialRentReconciliationItemProps = {
  fieldPrefix: string
  groupName: string
  baseUnit: any
  units: any[]
  rentComps: any[]
  includeInExport: boolean
  isGroupValid: boolean
  rentRollRentBasis: string
  classes: any
  form: any
  leaseTermsCalcType: string
}

const CommercialRentReconciliationItem: React.FC<CommercialRentReconciliationItemProps> = ({
  fieldPrefix,
  groupName,
  baseUnit,
  units,
  rentComps,
  includeInExport,
  isGroupValid,
  rentRollRentBasis,
  classes,
  form,
  leaseTermsCalcType,
}) => {
  const rentPsfLabel = useMemo(() => {
    return getRentPsfLabel(rentRollRentBasis, Labels.RENT_PER_SF_PER_MONTH, Labels.RENT_PER_SF)
  }, [rentRollRentBasis])

  const trendedRentPsfLabel = useMemo(() => {
    return getRentPsfLabel(rentRollRentBasis, Labels.TRENDED_PRICE_PER_SF_PER_MONTH, Labels.TRENDED_PRICE_PER_SF)
  }, [rentRollRentBasis])

  const adjustedRentPsfLabel = useMemo(() => {
    return getRentPsfLabel(rentRollRentBasis, Labels.ADJUSTED_RENT_PER_SF_PER_MONTH, Labels.ADJUSTED_RENT_PER_SF)
  }, [rentRollRentBasis])

  const disableInputs = !includeInExport

  const changeLeaseTermsCalcType = useCallback(
    (event: any) => {
      const newLeaseTermsCalcType = event.target.value
      const { batch, change } = form
      batch(() => {
        change(`${fieldPrefix}.leaseTermsCalcType`, newLeaseTermsCalcType)
        const updatedRentComps = rentComps.map((rentComp: any) => {
          return {
            ...rentComp,
            adjustments: {
              ...rentComp.adjustments,
              leaseTerms:
                newLeaseTermsCalcType === '$/SF'
                  ? rentComp.adjustments.leaseTerms * 100
                  : rentComp.adjustments.leaseTerms / 100,
            },
          }
        })
        change(`${fieldPrefix}.rentComps`, updatedRentComps)
      })
    },
    [fieldPrefix, form, rentComps]
  )

  const getCompInformationRowStyle = useCallback(
    (params: any) => {
      const formValues = form.getState().values
      if (formValues[`${fieldPrefix}.rentCompsExportInfo.${params.data.rowId}`]) {
        return { fontWeight: 'bold' }
      } else {
        return { fontWeight: 'normal' }
      }
    },
    [form, fieldPrefix]
  )

  const addCustomAdjustment = useCallback(() => {
    const { batch, change } = form
    const formValues = form.getState().values
    const customAdjustments = get(formValues, `${fieldPrefix}.customAdjustments`, {})
    const addedRowName = getNewAdjustmentName(customAdjustments)

    batch(() => {
      for (const rentComp of rentComps) {
        rentComp.customAdjustments[addedRowName] = 0
      }
      change(`${fieldPrefix}.customAdjustments`, { ...customAdjustments, [addedRowName]: addedRowName })
      change(`${fieldPrefix}.rentComps`, [...rentComps])
    })
  }, [form, fieldPrefix, rentComps])

  const deleteAdjustment = useCallback(
    (rowId: string) => {
      const { batch, change } = form
      const formValues = form.getState().values
      const physicalAdjustments: string[] = get(formValues, `${fieldPrefix}.physicalAdjustments`, [])
      batch(() => {
        const physicalAdjustmentIndex = physicalAdjustments.findIndex(adjustment => adjustment === rowId)
        if (REMOVABLE_PHYSICAL_ROWS.includes(rowId) && physicalAdjustmentIndex) {
          physicalAdjustments.splice(physicalAdjustmentIndex, 1)
          rentComps.forEach((rentComp: any) => {
            delete rentComp.adjustments[rowId]
          })
          change(`${fieldPrefix}.physicalAdjustments`, [...physicalAdjustments])
          change(`${fieldPrefix}.rentComps`, [...rentComps])
        } else {
          const customAdjustments = get(formValues, `${fieldPrefix}.customAdjustments`, {})
          if (customAdjustments[rowId]) {
            delete customAdjustments[rowId]
            rentComps.forEach((rentComp: any) => {
              delete rentComp.customAdjustments[rowId]
            })
            change(`${fieldPrefix}.customAdjustments`, { ...customAdjustments })
            change(`${fieldPrefix}.rentComps`, [...rentComps])
          }
        }
      })
    },
    [form, fieldPrefix, rentComps]
  )

  const handleUpdateAdjustmentRow = useCallback(
    (row: any) => {
      const { id, custom, label } = row
      const { batch, change } = form
      const formValues = form.getState().values
      const customAdjustments = get(formValues, `${fieldPrefix}.customAdjustments`, {})

      batch(() => {
        rentComps.forEach((rentComp: any) => {
          const { id: compId, adjustments, customAdjustments: compCustomAdjustments } = rentComp
          const newValue = row[compId]

          let numericValue
          if (id === 'leaseTerms' && leaseTermsCalcType === '$/SF') {
            numericValue = parseFloat(replace(newValue, '$/SF', ''))
          } else {
            numericValue = fromPercents(replace(newValue, '%', ''))
          }

          if (custom) {
            compCustomAdjustments[id] = numericValue
          } else {
            adjustments[id] = numericValue
          }
        })
        change(`${fieldPrefix}.rentComps`, [...rentComps])

        if (custom) {
          change(`${fieldPrefix}.customAdjustments`, { ...customAdjustments, [id]: label })
        }
      })
    },
    [form, fieldPrefix, rentComps, leaseTermsCalcType]
  )

  const handleUpdateCompInformationRow = useCallback(
    (row: any) => {
      const { id, rentCompsExportInfo } = row
      const { change } = form
      rentCompsExportInfo[id] = rentCompsExportInfo

      change(`${fieldPrefix}.rentCompsExportInfo`, { ...rentCompsExportInfo })
    },
    [form, fieldPrefix]
  )

  const columns: RowBasedTableColumn[] = useMemo(() => {
    const columns: RowBasedTableColumn[] = [
      {
        name: 'label',
        label: '',
        type: ColumnDataTypeEnum.text,
        minWidth: 350,
        permanent: true,
        align: 'left',
        editable: params => {
          if (params.data.custom) {
            return true
          }
          return false
        },
      },
    ]
    rentComps.forEach((comp: any) => {
      columns.push({
        name: comp.id,
        label: comp.address,
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: params => {
          if (params.data.totalRow) {
            return false
          }
          return true
        },
      })
    })
    return columns
  }, [rentComps])
  const compInformationColumns: RowBasedTableColumn[] = useMemo(() => {
    const columns: RowBasedTableColumn[] = [
      {
        name: 'rentCompsExportInfo',
        label: 'Export',
        type: ColumnDataTypeEnum.boolean,
        minWidth: 85,
        maxWidth: 85,
        permanent: true,
        align: 'center',
        editable: false,
        cellRendererFramework({ value, data: { rowId } }) {
          return <Checkbox name={`${fieldPrefix}.rentCompsExportInfo[${rowId}]`} />
        },
      },
      {
        name: 'label',
        label: '',
        type: ColumnDataTypeEnum.text,
        minWidth: 200,
        permanent: true,
        align: 'left',
        editable: false,
      },
      {
        name: 'baseUnit',
        label: 'Base Unit',
        type: ColumnDataTypeEnum.text,
        minWidth: 200,
        permanent: true,
        align: 'left',
        editable: false,
      },
    ]
    units.forEach((unit: any, index: number) => {
      columns.push({
        name: unit._id,
        label: `Subject Unit ${index + 1}`,
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: false,
      })
    })
    rentComps.forEach((comp: any) => {
      columns.push({
        name: comp.id,
        label: comp.address,
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: false,
      })
    })
    return columns
  }, [units, rentComps, fieldPrefix])

  const physicalAdjustments: string[] = useMemo(() => {
    const formValues = form.getState().values
    return get(formValues, `${fieldPrefix}.physicalAdjustments`, [])
  }, [form, fieldPrefix])
  const customAdjustments: { [key: string]: string } = useMemo(() => {
    const formValues = form.getState().values
    return get(formValues, `${fieldPrefix}.customAdjustments`, {})
  }, [form, fieldPrefix])

  const marketAdjustmentsRows = useMemo(
    () => getMarketRows(rentComps, leaseTermsCalcType, disableInputs),
    [rentComps, leaseTermsCalcType, disableInputs]
  )
  const physicalAdjustmentsRows = useMemo(
    () => getPhysicalRows(physicalAdjustments, customAdjustments, rentComps, leaseTermsCalcType, disableInputs),
    [physicalAdjustments, customAdjustments, rentComps, leaseTermsCalcType, disableInputs]
  )

  const totalRows = useMemo(() => {
    const totalsByCompId: any[] = []
    const totalRowsByType: any = {}

    rentComps.forEach((comp: any) => {
      totalsByCompId.push({
        id: comp.id,
        trendedPricePSF: comp.dateSignedTrendedPricePerSF,
        total: comp.totalAdjustment,
        adjustedPricePSF: comp.adjustedRentPerSF,
      })
    })
    const groupNames = [
      { name: 'trendedPricePSF', label: trendedRentPsfLabel },
      { name: 'total', label: Labels.TOTAL_ADJUSTMENT },
      { name: 'adjustedPricePSF', label: adjustedRentPsfLabel },
    ]
    groupNames.forEach(group => {
      let formatter: (value: any) => any

      if (group.name === 'total') {
        formatter = formatPercentageString
      } else {
        formatter = formatCurrencyFloat
      }

      const totalRow: any = {
        readOnly: true,
        suppressMovable: true,
        permanent: false,
        type: ColumnDataTypeEnum.text,
        id: group.name,
        label: group.label,
        // TODO: make total row sticky
        rowDef: { hideAction: true, summary: true },
        totalRow: true,
      }

      totalsByCompId.forEach(total => {
        const { id } = total
        totalRow[id] = formatter(total[group.name])
      })

      totalRowsByType[group.name] = totalRow
    })
    return totalRowsByType
  }, [rentComps, trendedRentPsfLabel, adjustedRentPsfLabel])

  const compInformationRows = useMemo(() => {
    const rows = getCompInformationRows(rentPsfLabel)

    rows.map((row: any) => {
      const { formatter } = row

      const baseUnitValue = get(baseUnit, row.rowId)
      row.baseUnit = formatter ? formatter(baseUnitValue) : baseUnitValue

      units.forEach((unit: any) => {
        const { _id } = unit
        const value = get(unit, row.rowId)
        row[_id] = formatter ? formatter(value) : value
      })

      rentComps.forEach((comp: any) => {
        const { id } = comp
        const value = get(comp, row.id)
        row[id] = formatter ? formatter(value) : value
      })

      return row
    })
    return rows
  }, [units, rentComps, rentPsfLabel, baseUnit])

  const marketRows = [...marketAdjustmentsRows, totalRows.trendedPricePSF]
  const physicalRows = [...physicalAdjustmentsRows, totalRows.total, totalRows.adjustedPricePSF]

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">{`${groupName} ${Labels.RECONCILIATION}`}</Typography>
          <CheckboxWithLabel name={`${fieldPrefix}.includeInExport`} label="Include adjustment grid in report." />
        </Grid>
        <Grid item xs={12}>
          <DropDown
            className={classes.dropdown}
            name={`${fieldPrefix}.leaseTermsCalcType`}
            label="Lease Terms Calculations"
            items={CALC_TYPES}
            onChange={changeLeaseTermsCalcType}
          />
        </Grid>
        {isGroupValid ? (
          <>
            <Grid item xs={12}>
              <Grid item sx={{ paddingBottom: '12px' }}>
                <Typography variant="subtitle2">Market Adjustments</Typography>
              </Grid>
              <RowBasedTable
                id="market-adjustments-table"
                columns={columns}
                rows={marketRows}
                onRowUpdate={handleUpdateAdjustmentRow}
                onManyRowsUpdate={noop}
                getCustomColumnConfig={getCustomColumnConfig}
                hideIndexColumn
                onColumnDragEnd={noop}
                onRowsDragEnd={noop}
                onColumnDelete={noop}
                onColumnUpdate={noop}
                onRowDelete={noop}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid item sx={{ paddingBottom: '12px', display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="subtitle2">Comp Information</Typography>
              </Grid>
              <RowBasedTable
                id="comp-information-table"
                columns={compInformationColumns}
                rows={compInformationRows}
                getRowStyle={getCompInformationRowStyle}
                onRowUpdate={handleUpdateCompInformationRow}
                onManyRowsUpdate={noop}
                getCustomColumnConfig={getCustomColumnConfig}
                hideIndexColumn
                onColumnDragEnd={noop}
                onRowsDragEnd={noop}
                onColumnDelete={noop}
                onColumnUpdate={noop}
                onRowDelete={noop}
                disableVirtualization
              />
            </Grid>
            <Grid item xs={12}>
              <Grid item container justifyContent="space-between" alignItems="baseline">
                <Grid item>
                  <Typography variant="subtitle2">Adjustments</Typography>
                </Grid>
                <Grid item>
                  <Button variant="text" onClick={addCustomAdjustment}>
                    Add Adjustment
                  </Button>
                </Grid>
              </Grid>
              <RowBasedTable
                id="physical-adjustments-table"
                columns={columns}
                rows={physicalRows}
                onRowUpdate={handleUpdateAdjustmentRow}
                onManyRowsUpdate={noop}
                getCustomColumnConfig={getCustomColumnConfig}
                hideIndexColumn
                onColumnDragEnd={noop}
                onRowsDragEnd={noop}
                onColumnDelete={noop}
                onColumnUpdate={noop}
                onRowDelete={deleteAdjustment}
                disableVirtualization
              />
            </Grid>
            <Grid item xs={12}>
              <Number
                name={`${fieldPrefix}.marketRentConclusion`}
                label={Labels.MARKET_RENT_CONCLUSION}
                decimalScale={2}
                prefix="$"
                allowNegative={false}
                thousandSeparator
                validate={required}
                className={classes.numberField}
              />
              <DropDown
                name={`${fieldPrefix}.assumedLeaseTerms`}
                label={Labels.LEASE_TERMS}
                items={LeaseTermsOptions}
                className={classes.dropdown}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <AlertInfo>{Labels.SKIP_THIS_GROUP_BECAUSE_THIS_GROUP_HAS_NOTHING_FOR_COMPARISON}</AlertInfo>
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}

export default withStyles(styles)(CommercialRentReconciliationItem)
