export const DEFAULT_COMP_INFO_ROW_IDS = {
  tenantName: 'tenantName',
  psfRent: 'psfRent',
  dateSigned: 'dateSigned',
  leaseTerms: 'leaseTerms',
  squareFeet: 'squareFeet',
  frontage: 'frontage',
  ceilingHeight: 'ceilingHeight',
  location: 'location',
}

export const COMP_INFO_ROW_IDS_TO_PROPERTY = {
  [DEFAULT_COMP_INFO_ROW_IDS.tenantName]: 'tenantName',
  [DEFAULT_COMP_INFO_ROW_IDS.psfRent]: 'psfRent',
  [DEFAULT_COMP_INFO_ROW_IDS.dateSigned]: 'lease.signedAt',
  [DEFAULT_COMP_INFO_ROW_IDS.leaseTerms]: 'lease.terms',
  [DEFAULT_COMP_INFO_ROW_IDS.squareFeet]: 'squareFeet',
  [DEFAULT_COMP_INFO_ROW_IDS.frontage]: 'description.frontage',
  [DEFAULT_COMP_INFO_ROW_IDS.ceilingHeight]: 'description.ceilingHeight',
  [DEFAULT_COMP_INFO_ROW_IDS.location]: 'description.location',
}

export const COMP_INFO_ROW_IDS_TO_LABEL = {
  [DEFAULT_COMP_INFO_ROW_IDS.tenantName]: 'Tenant Name',
  [DEFAULT_COMP_INFO_ROW_IDS.dateSigned]: 'Date Signed',
  [DEFAULT_COMP_INFO_ROW_IDS.leaseTerms]: 'Lease Terms',
  [DEFAULT_COMP_INFO_ROW_IDS.squareFeet]: 'Square Feet',
  [DEFAULT_COMP_INFO_ROW_IDS.frontage]: 'Frontage',
  [DEFAULT_COMP_INFO_ROW_IDS.ceilingHeight]: 'Ceiling Height',
  [DEFAULT_COMP_INFO_ROW_IDS.location]: 'Corner',
}

export const MARKET_ROWS = ['leaseTerms', 'dateSigned']

export const REMOVABLE_PHYSICAL_ROWS = [
  'locationWithNeighborhood',
  'frontage',
  'ceilingHeight',
  'location',
  'condition',
]
