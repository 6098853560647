import React from 'react'
import { get } from 'lodash'

import { COMMERCIAL_RENT_RECONCILIATION_PATH } from 'shared/constants/report/keysAndDataPaths'

import wrapForm from '../../../wrapForm'

import CommercialRentReconciliation from './CommercialRentReconciliation'

import { Labels } from './CommercialRentReconciliationConstants'

export const DATA_PATH = COMMERCIAL_RENT_RECONCILIATION_PATH

class CommercialRentReconciliationContainer extends React.PureComponent {
  render() {
    const { propertyType } = this.props
    const form = this.props.form
    const formValues = form.getState().values
    const { reconciliationGroups, rentRollRentBasis } = formValues
    return (
      <CommercialRentReconciliation
        commercialRentReconciliation={reconciliationGroups.items}
        rentRollRentBasis={rentRollRentBasis}
        propertyType={propertyType}
        form={form}
      />
    )
  }
}

export default wrapForm(DATA_PATH, { heading: Labels.COMMERCIAL_RENT_RECONCILIATION }, state => {
  return {
    propertyType: get(state, 'report.reportData.propertyType'),
  }
})(CommercialRentReconciliationContainer)
