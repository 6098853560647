import React from 'react'
import PropTypes from 'prop-types'
import { get, remove } from 'lodash'
import arrayMutators from 'final-form-arrays'
import ObjectID from 'bson-objectid'

import { COMMERCIAL_COMP_GROUPS_PATH } from 'shared/constants/report/keysAndDataPaths'

import wrapForm from '../../../../wrapForm'

import { Labels, UNSORTED_GROUP_ID } from '../constants'

import { LEASE_TERMS_LIST } from '../../../../../constants/commercial'

import CommercialCompGroups from './CommercialCompGroups'

export const DATA_PATH = COMMERCIAL_COMP_GROUPS_PATH

class CommercialCompGroupsContainer extends React.PureComponent {
  static propTypes = {
    form: PropTypes.object.isRequired,
  }

  getUnitData = unit => {
    const { _id, isVacant, rentPsf, tenantName, vacantName, isBaseUnit } = unit
    const { signedAt, expiresAt, terms } = unit.lease

    const selectedTerm = LEASE_TERMS_LIST.find(leaseTermInfo => leaseTermInfo.value === terms)

    return {
      _id,
      isVacant,
      tenantName,
      vacantName,
      signedAt,
      expiresAt,
      terms: get(selectedTerm, 'label', ''),
      rentPsf,
      isBaseUnit: isBaseUnit || false,
    }
  }

  getGroupsWithRentRollUnits = (compGroups, units) => {
    const mappedVacantUnits = units.map((unit, index) => {
      if (unit.isVacant) {
        unit.vacantName = `Commercial Unit ${index + 1}`
      }
      return unit
    })
    const unitsNotInGroup = [...mappedVacantUnits]
    const groupsWithUnits = []

    compGroups.forEach(compGroup => {
      const unitsInGroup = remove(unitsNotInGroup, unit => compGroup.unitIds.includes(unit._id))

      const mappedGroupUnits = unitsInGroup.map(unit => {
        const mappedUnit = this.getUnitData(unit)
        return {
          ...mappedUnit,
          isBaseUnit: mappedUnit._id === compGroup.baseUnitId,
        }
      })

      if (!compGroup.baseUnitId && mappedGroupUnits.length) {
        mappedGroupUnits[0].isBaseUnit = true
      }

      groupsWithUnits.push({
        _id: compGroup._id,
        name: compGroup.name,
        units: mappedGroupUnits,
      })
    })

    groupsWithUnits.unshift({
      _id: UNSORTED_GROUP_ID,
      name: Labels.UNSORTED,
      units: unitsNotInGroup.map(unit => this.getUnitData(unit)),
    })

    return groupsWithUnits
  }

  addCompGroup = name => {
    const { form } = this.props

    form.mutators.push('compGroups', {
      _id: ObjectID().toJSON(),
      newCompGroup: true,
      name,
      unitIds: [],
    })
  }

  removeCompGroup = compGroupId => {
    const { form } = this.props
    const compGroups = get(form, 'values.compGroups', [])

    const compGroupIndex = compGroups.findIndex(group => group._id === compGroupId)

    form.mutators.remove('compGroups', compGroupIndex)
  }

  changeGroupName = (compGroupId, name) => {
    const { form } = this.props
    const compGroups = get(form, 'values.compGroups', [])

    const compGroupIndex = compGroups.findIndex(group => group._id === compGroupId)
    const group = compGroups[compGroupIndex]
    form.mutators.update('compGroups', compGroupIndex, { ...group, name })
  }

  moveUnit = ({ source, destination, ...other }) => {
    if (!destination) {
      return
    }
    const { form } = this.props
    const compGroups = get(form, 'values.compGroups', [])

    form.batch(() => {
      const fromCompGroupIndex = compGroups.findIndex(group => group._id === source.droppableId)
      if (fromCompGroupIndex > -1) {
        const fromGroup = compGroups[fromCompGroupIndex]
        const fromGroupUnitsCount = fromGroup.unitIds.length
        if (fromGroupUnitsCount === 2) {
          const newBaseUnitId = fromGroup.unitIds.find(unitId => unitId !== other.draggableId)
          form.change(`compGroups[${fromCompGroupIndex}].baseUnitId`, newBaseUnitId)
        }

        const newUnitIds = fromGroup.unitIds.toSpliced(source.index, 1)
        form.change(`compGroups[${fromCompGroupIndex}].unitIds`, newUnitIds)
      }

      const toCompGroupIndex = compGroups.findIndex(group => group._id === destination.droppableId)
      if (toCompGroupIndex > -1) {
        const toGroupUnitsCount = compGroups[toCompGroupIndex].unitIds.length
        const toGroup = compGroups[toCompGroupIndex]
        if (!toGroupUnitsCount) {
          form.change(`compGroups[${toCompGroupIndex}].baseUnitId`, other.draggableId)
        }

        const newUnitIds = toGroup.unitIds.toSpliced(destination.index, 0, other.draggableId)
        form.change(`compGroups[${toCompGroupIndex}].unitIds`, newUnitIds)
      }
    })
  }

  changeBaseUnit = (groupId, unitId) => {
    const { form } = this.props
    const compGroups = get(form, 'values.compGroups', [])

    const updatedCompGroups = compGroups.map(compGroup => {
      if (compGroup._id === groupId && compGroup.unitIds.includes(unitId)) {
        return {
          ...compGroup,
          baseUnitId: unitId,
        }
      }
      return compGroup
    })

    form.change('compGroups', updatedCompGroups)
  }

  render() {
    const { form } = this.props
    const compGroups = get(form, 'values.compGroups', [])
    const units = get(form, 'values.units', [])
    const rentBasis = get(form, 'values.rentBasis')
    const groupsWithRentRollUnits = this.getGroupsWithRentRollUnits(compGroups, units)

    return (
      <CommercialCompGroups
        compGroups={groupsWithRentRollUnits}
        thereAreNoCommercialUnits={units.length === 0}
        rentBasis={rentBasis}
        onAddCompGroup={this.addCompGroup}
        onChangeGroupName={this.changeGroupName}
        onRemoveCompGroup={this.removeCompGroup}
        onMoveUnit={this.moveUnit}
        onChangeBaseUnit={this.changeBaseUnit}
      />
    )
  }
}

export default wrapForm(DATA_PATH, {
  heading: Labels.COMMERCIAL_COMP_GROUPS,
  registeredFields: ['compGroups'],
  mutators: {
    ...arrayMutators,
  },
})(CommercialCompGroupsContainer)
