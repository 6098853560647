import { RentTypes } from '../../../client/src/report/constants'

// updated on Jun 20, 2024 - however these are now moved to CMS
export const NYC_RENT_TYPE_ANNUAL_INCREASES = [
  { rentType: RentTypes.RENT_CONTROLLED, increase: 1.0195 },
  { rentType: RentTypes.RENT_STABILIZED, increase: 1.027 },
  { rentType: RentTypes.SECTION_8, increase: 1 },
  { rentType: RentTypes.MARKET_RATE, increase: 1 },
  { rentType: RentTypes.MONTH_TO_MONTH, increase: 1 },
]

export const DEFAULT_RENT_TYPE_ANNUAL_INCREASES = [
  { rentType: RentTypes.RENT_CONTROLLED, increase: 1 },
  { rentType: RentTypes.RENT_STABILIZED, increase: 1 },
  { rentType: RentTypes.SECTION_8, increase: 1 },
  { rentType: RentTypes.MARKET_RATE, increase: 1 },
  { rentType: RentTypes.MONTH_TO_MONTH, increase: 1 },
]

export const PROJECTED = 'Projected'

export const ONLY_NUMBERS = '^-?\\d*\\.?\\d*$'

export const ONLY_INTEGERS = '^(0|[1-9][0-9]*)$'

export const ONLY_FLOAT = '^[0-9]*[.][0-9]+$'

export const EMAIL_MATCH = '^(\\S+)@([a-z0-9-]+)(\\.)([a-z]{2,4})(\\.?)([a-z]{0,4})+$'

export const BOWERY_EMAIL = '^(\\S+)@boweryvaluation.com'
