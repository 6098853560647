import React, { useCallback, useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import arrayMutators from 'final-form-arrays'
import { find, findIndex, get, isEqual, pick } from 'lodash'
import { Paper, Link } from '@material-ui/core'
import { Link as ReactRouterLink } from 'react-router-dom'
import LinkIcon from '@mui/icons-material/Launch'

import { useFieldArray } from 'react-final-form-arrays'
import { useColumnBasedColumnsApi, useColumnBasedRowsApi } from '@bowery-valuation/ui-components'
import { FeatureToggle } from '@bowery-valuation/feature-flagger-client'
import RefHolder from 'client-shared/utils/RefHolder'

import { Box, Button, Stack, Typography, Grid } from '@mui/material'

import { compose } from 'recompose'

import ObjectID from 'bson-objectid'

import useBoolean from 'client-shared/hooks/useBoolean'
import { Callout, CheckboxWithLabel } from 'client-shared/components'
import AutomationCTA from 'client-shared/components/AutomationCTA'
import { IMPORT_STATUS_READY } from 'client-shared/components/AutomationCTA/constants'
import AutomationStatus from 'client-shared/components/AutomationStatus'
import { COMPARABLE_EXPENSES_PATH } from 'shared/constants/report/keysAndDataPaths'
import { FEATURE_FLAG_AUTO_EXPENSE_COMPS, FEATURE_FLAG_SIMPLIFIED_EXPENSE_COMPS } from 'shared/constants/featureFlags'
import { formatExpenseId } from 'shared/helpers/incomeApproach/expenses/helpers'

import { DEFAULT_ALERT_MESSAGES } from 'shared/constants/automation/messages'
import { mapExpenseCompPlex } from 'shared/helpers/incomeApproach/expenses/mappers'
import SelectField from 'client-shared/components/_mui5/Select/select-field'

import wrapForm from '../../wrapForm'

import { errorNotification } from '../../../../shared/redux/actions/notifications'
import { saveReport } from '../../../redux/actions/report'
import AddExpenseDialog from '../../../components/AddExpenseDialog/AddExpenseDialog'

import { withMicrofrontend } from '../../../../shared/hooks/useMicrofrontend'

import { BASIS_OF_COMPARISON_ITEMS, REAL_ESTATE_TAXES } from '../../../../../../shared/constants/expenses'

import { ComparableExpensesRecords } from '../../../../../../shared/utils/comparableExpensesRecords/expensesRecords'

import * as Api from '../../../api'

import { defineReservedNames, validateName } from '../helpers'

import { EXPENSE_MODAL_NAME } from '../ExpenseHistory/constants'

import { EXPENSE_COMPARABLES_CATEGORIES } from '../../../../../../shared/constants/expenses/expenseComparables'

import { columnCalculations, computedValueDecorator, rowCalculations } from './decorators'
import ComparableExpensesTable from './ComparableExpensesTable'
import { areExpenseCategoriesEqual, getResUnitsAndSqft } from './helpers'
import styles from './style'

import ComparableExpensesImport from './ComparableExpensesImport'

import ComparableExpensesPanel from './ComparableExpensesPanel'
import ExpenseForecastSummaryTable from './ExpenseForecastSummaryTable'

import SourceLinksModal from './SourceLinksModal'
import RemovedExpenseCompsTable from './RemovedExpenseCompsTable'

const DATA_PATH = COMPARABLE_EXPENSES_PATH
const heading = 'Comparable Expenses'
const headingTable = 'Expense Comparables'
const normalizeIndex = index => index - 1

const RouterLink = RefHolder(ReactRouterLink)

export const ComparableExpenses = props => {
  const {
    classes,
    propertyType,
    propertySummary,
    authenticatedUser,
    compPlexLoaded,
    reportId,
    errorNotification,
    saveReport,
    expenseAutomationSettings,
    isStrictExpensesMode,
    yearBuilt,
    totalSubjectOperatingExpensesNetTaxes,
    expenseHistory,
    residentialUnitCount,
    grossBuildingArea,
    currentPath,
  } = props

  const form = props.form
  const formState = form.getState()
  const formValues = formState.values

  const {
    utilitiesExpensesMode,
    basisOfComparison,
    expenseCategories,
    comparableExpenses,
    showExpenseRatio,
    expenseRatio,
    showEGI,
    showRETaxes,
    totalOperatingExpenses,
    totalOperatingExpensesPerSF,
    totalOperatingExpensesPerUnit,
    comparableExpensesAutomationMetadata,
    expenseForecast,
    removedExpenseComps,
  } = formValues
  const { fields: expenseCategoriesFields } = useFieldArray('expenseCategories', {
    isEqual: areExpenseCategoriesEqual,
  })

  useFieldArray('comparableExpenses', { isEqual: isEqual })

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCompModalOpen, setIsCompModalOpen] = useState(false)
  const [compModalData, setCompModalData] = useState(null)
  const [latestCompVersions, setLatestCompVersions] = useState([])
  const [isSourceLinksModalOpen, openSourceLinksModal, closeSourceLinksModal] = useBoolean(false)

  const handleClickOpen = () => {
    setIsModalOpen(true)
  }
  const handleClickClose = () => {
    setIsModalOpen(false)
  }

  const shouldRefreshTable = useMemo(() => {
    return formState.submitFailed && !formState.modifiedSinceLastSubmit
  }, [formState.submitFailed, formState.modifiedSinceLastSubmit])

  const reservedNames = [...defineReservedNames(utilitiesExpensesMode), REAL_ESTATE_TAXES.name]

  const excludedExpenses = [...expenseCategoriesFields.value.map(field => field.name), ...reservedNames]

  const loadLatestExpensesVersions = useCallback(async () => {
    const shouldLoadLatestCompVersion = !!compPlexLoaded

    if (shouldLoadLatestCompVersion) {
      const ids = comparableExpenses.map(expense => expense.boweryId).filter(Boolean)

      if (ids.length) {
        const { comps } = await Api.fetchExpenseCompsVersionsFromCompPlex(ids)
        setLatestCompVersions(comps)
      }
    }
  }, [compPlexLoaded, comparableExpenses])

  useEffect(() => {
    loadLatestExpensesVersions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compPlexLoaded, comparableExpenses])

  const closeCompModal = useCallback(() => {
    setIsCompModalOpen(false)
  }, [])

  const validateExpenseName = value => {
    return validateName(value, expenseCategoriesFields.value, reservedNames)
  }

  const addCategory = useCallback(
    (categoryId, categoryName) => {
      const { records, categories } = ComparableExpensesRecords.from({
        records: comparableExpenses,
        categories: expenseCategoriesFields.value,
        propertyType,
        getResUnitsAndSqft,
        utilitiesExpensesMode,
        includeEGI: showEGI,
        includeRealEstateTaxes: showRETaxes,
      })
        .addCategory(categoryId, categoryName)
        .normalizeCategories()

      form.batch(() => {
        form.change('comparableExpenses', records)
        form.change('expenseCategories', categories)
      })
    },
    [comparableExpenses, expenseCategoriesFields.value, form, propertyType, utilitiesExpensesMode, showEGI, showRETaxes]
  )

  const removeCategory = useCallback(
    categoryId => {
      const {
        values: { expenseCategories, comparableExpenses },
      } = form.getState()
      const { records, categories } = ComparableExpensesRecords.from({
        records: comparableExpenses,
        categories: expenseCategories,
        propertyType,
        getResUnitsAndSqft,
        utilitiesExpensesMode,
        includeEGI: showEGI,
        includeRealEstateTaxes: showRETaxes,
      })
        .removeCategory(categoryId)
        .normalizeCategories()

      form.batch(() => {
        form.change('expenseCategories', categories)
        form.change('comparableExpenses', records)
      })
    },
    [form, utilitiesExpensesMode, propertyType, showEGI, showRETaxes]
  )
  const columnsApi = useColumnBasedColumnsApi({
    columnItemsPath: 'comparableExpenses',
    valuesPath: 'expenses',
    normalizeIndex,
  })

  const rowsApi = useColumnBasedRowsApi({
    rowDefinitionsPath: 'expenseCategories',
    columnItemsPath: 'comparableExpenses',
    valuesPaths: 'expenses',
  })

  const deleteColumn = useCallback(
    column => {
      const { mutators } = form
      const currentFormState = form.getState()
      const { comparableExpenses } = currentFormState.values
      const compToDelete = find(comparableExpenses, comp => comp._id === column.field)
      mutators.push('removedExpenseComps', compToDelete)

      columnsApi.deleteColumn(column)
    },
    [form, columnsApi]
  )

  const addRow = modalFormValues => {
    const { expenseName } = modalFormValues
    const newId = formatExpenseId(expenseName)
    addCategory(newId, expenseName)
    handleClickClose()
  }

  const addExpenseComparables = useCallback(
    expenseComps => {
      const { comparableExpenses } = formValues

      const { categories, records } = ComparableExpensesRecords.from({
        categories: expenseCategoriesFields.value,
        records: comparableExpenses,
        propertyType,
        getResUnitsAndSqft,
        utilitiesExpensesMode,
        includeEGI: showEGI,
        includeRealEstateTaxes: showRETaxes,
      })
        .addRecords(expenseComps, showRETaxes, showEGI)
        .normalizeCategories()
      form.batch(() => {
        form.change('expenseCategories', categories)
        form.change('comparableExpenses', records)
      })
    },
    [formValues, expenseCategoriesFields.value, propertyType, form, utilitiesExpensesMode, showEGI, showRETaxes]
  )

  const updateExpenseComparable = useCallback(
    expenseComp => {
      const formValues = get(form.getState(), 'values', {})
      const { comparableExpenses, showEGI, showRETaxes } = formValues

      const { records, categories } = ComparableExpensesRecords.from({
        records: comparableExpenses,
        categories: expenseCategoriesFields.value,
        propertyType,
        getResUnitsAndSqft,
        utilitiesExpensesMode,
        includeEGI: showEGI,
        includeRealEstateTaxes: showRETaxes,
      })
        .updateRecord(expenseComp, showRETaxes, showEGI)
        .normalizeCategories()

      form.batch(() => {
        form.change('expenseCategories', categories)
        form.change('comparableExpenses', records)
      })

      const newLatestCompVersions = { ...latestCompVersions }
      newLatestCompVersions[expenseComp.id] = expenseComp.version
      setLatestCompVersions(newLatestCompVersions)

      closeCompModal()
    },
    [
      expenseCategoriesFields.value,
      propertyType,
      form,
      utilitiesExpensesMode,
      closeCompModal,
      setLatestCompVersions,
      latestCompVersions,
    ]
  )

  const addBlankComp = () => {
    addExpenseComparables([
      {
        manuallyCreated: true,
        key: ObjectID().toString(),
        yearBuilt: null,
        propertyType: null,
        expenses: form.values.expenseCategories.map((expense, index) => ({
          ...expense,
          value: undefined,
        })),
      },
    ])
  }

  const toggleEGI = useCallback(
    event => {
      if (event.target.value === 'true') {
        removeCategory(EXPENSE_COMPARABLES_CATEGORIES.egi)
      } else {
        addCategory(EXPENSE_COMPARABLES_CATEGORIES.egi)
      }
      form.change('showEGI', event.target.checked)
    },
    [addCategory, removeCategory, form]
  )

  const toggleRealEstateTaxes = useCallback(
    event => {
      if (event.target.value === 'true') {
        removeCategory(REAL_ESTATE_TAXES.key)
      } else {
        addCategory(REAL_ESTATE_TAXES.key)
      }
    },
    [addCategory, removeCategory]
  )

  const handleExpenseCompPlexAddition = useCallback(
    ({ detail: { expenseRecord } }) => {
      const webappComp = mapExpenseCompPlex(expenseRecord)
      const { comparableExpenses } = formValues
      if (comparableExpenses.find(comp => comp.boweryId === webappComp.boweryId)) {
        return
      }
      addExpenseComparables([webappComp])
    },
    [addExpenseComparables, formValues]
  )

  const handleExpenseCompPlexEditing = useCallback(
    ({ detail: { expenseRecord } }) => {
      const localExpenseComp = find(comparableExpenses, expense => expense.boweryId === expenseRecord.id)
      const webappComp = mapExpenseCompPlex(expenseRecord, localExpenseComp)

      updateExpenseComparable(webappComp)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const changeCompModalMode = useCallback((mode, comp) => {
    setIsCompModalOpen(true)
    setCompModalData({ boweryId: comp.boweryId, version: comp.version })
  }, [])

  const reAddRemovedComp = useCallback(
    compToReAdd => {
      const currentFormState = form.getState()
      const removedExpenseComps = currentFormState.values.removedExpenseComps
      const compToDeleteIndex = findIndex(removedExpenseComps, removedComp => removedComp._id === compToReAdd._id)
      form.mutators.remove('removedExpenseComps', compToDeleteIndex)

      addExpenseComparables([compToReAdd])
    },
    [addExpenseComparables, form]
  )

  const deleteRemovedComp = useCallback(
    compToDelete => {
      const currentFormState = form.getState()
      const removedExpenseComps = currentFormState.values.removedExpenseComps
      const compToDeleteIndex = findIndex(removedExpenseComps, removedComp => removedComp._id === compToDelete._id)
      form.mutators.remove('removedExpenseComps', compToDeleteIndex)
    },
    [form]
  )

  const clearRemovedTable = useCallback(() => {
    form.change('removedExpenseComps', [])
  }, [form])

  const getAutoExpenseComps = useCallback(async () => {
    const data = await Api.getAutoExpenseComps(
      reportId,
      {
        expenses: {
          residentialUnits: propertySummary.residentialUnitCount,
          squareFeet: propertySummary.squareFootage,
        },
        totalPerUnit: totalSubjectOperatingExpensesNetTaxes.perUnit,
      },
      expenseAutomationSettings
    )

    if (!data) {
      throw new Error('There is no data to use for expense comps automation.')
    }

    if (!!data.status && data.status !== 200) {
      throw new Error(data.message || data.response)
    }

    const expenseComps = (data.expenseComps || []).map(comp => ({
      ...comp,
      isAutoComp: true,
    }))
    addExpenseComparables(expenseComps)

    const automationMetadata = {
      sourceName: 'comp-are',
      sourceLinks: data.recentSimilarReports,
      updatedAt: new Date(),
      importStatus: IMPORT_STATUS_READY,
    }

    form.change('comparableExpensesAutomationMetadata', automationMetadata)

    const formDataPath = DATA_PATH.join('.')
    global.automationSaved = 'autoExpenseComps'
    saveReport(formDataPath)
  }, [
    reportId,
    expenseAutomationSettings,
    addExpenseComparables,
    propertySummary.residentialUnitCount,
    propertySummary.squareFootage,
    totalSubjectOperatingExpensesNetTaxes,
    form,
    saveReport,
  ])

  const automationWarnings = useMemo(() => {
    const result = []

    if (!isStrictExpensesMode) {
      result.push('Expense history table is not using strict mode.')
    }

    if (!yearBuilt) {
      result.push('No year built.')
    }

    return result
  }, [isStrictExpensesMode, yearBuilt])

  const automationSuccessMessage = useMemo(() => {
    const sourceLinks = get(comparableExpensesAutomationMetadata, 'sourceLinks', [])
    return (
      <div>
        <div>
          Your automation ran successfully.{' '}
          {sourceLinks.length ? 'The following recent similar reports were used:' : ''}
        </div>
        <ul>
          {sourceLinks.map(({ report }) => (
            <li key={report.id}>
              <Link style={{ cursor: 'pointer' }} href={`/report/${report.id}/comparable-expenses`} target="_blank">
                {report.address}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    )
  }, [comparableExpensesAutomationMetadata])

  return (
    <div>
      <Grid container spacing={1}>
        <Stack sx={{ width: '100%', position: 'relative' }}>
          <ComparableExpensesPanel
            authenticatedUser={authenticatedUser}
            isCompModalOpen={isCompModalOpen}
            compModalData={compModalData}
            errorNotification={errorNotification}
            onComparableAdd={handleExpenseCompPlexAddition}
            onComparableEdit={handleExpenseCompPlexEditing}
            closeCompModal={closeCompModal}
          />
          <Stack
            sx={{
              position: 'absolute', // need this styles until migrate card to comp-plex
              top: 36,
              left: 300,
            }}
          >
            <ComparableExpensesImport
              addExpenseComparables={addExpenseComparables}
              showRETaxes={showRETaxes}
              {...props}
            />
          </Stack>
        </Stack>
        <FeatureToggle featureFlag={FEATURE_FLAG_AUTO_EXPENSE_COMPS}>
          <Grid item>
            <AutomationCTA
              CTAMessage="Automate your selection of expense comparables through Bowery AI."
              successMessage={automationSuccessMessage}
              errorMessage={DEFAULT_ALERT_MESSAGES.ERROR}
              onAutomationRun={getAutoExpenseComps}
              disableCTA={automationWarnings.length > 0}
              warningTitle={automationWarnings.length > 0 && 'Cannot Run Expense Comps Automation'}
              warningMessage={
                automationWarnings.length > 0 && (
                  <div className="automation-warning">
                    <ul>
                      {automationWarnings.map(warning => {
                        return <li key={warning}>{warning}</li>
                      })}
                    </ul>
                  </div>
                )
              }
            />
          </Grid>
        </FeatureToggle>
        <Grid item xs={12}>
          <Callout
            content={
              <Typography variant="body1">
                Information added to the Blank Column will NOT be saved and surfaced as a comp in the future
              </Typography>
            }
            variant="info"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Grid item>
            <Paper className={classes.paper_table}>
              <Grid container justify="space-between">
                <Grid item>
                  <Typography variant="h6" className={classes.paper_header}>
                    Expense Forecast Summary
                  </Typography>
                </Grid>
                <Grid item>
                  <RouterLink
                    to={{
                      pathname: 'expense-forecast',
                      state: { returnPath: currentPath },
                    }}
                  >
                    <Button variant="text">
                      EDIT FORECAST <LinkIcon sx={{ paddingLeft: '4px', fontSize: '16px' }} />
                    </Button>
                  </RouterLink>
                </Grid>
              </Grid>
              <ExpenseForecastSummaryTable
                basis={basisOfComparison}
                expenseHistory={expenseHistory}
                expenseForecast={expenseForecast}
                expenseComps={comparableExpenses}
                residentialUnitCount={residentialUnitCount}
                grossBuildingArea={grossBuildingArea}
              />
            </Paper>
            <Paper className={classes.paper_table}>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.paper_header}>
                  {headingTable}
                </Typography>
              </Grid>
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <SelectField
                    name="basisOfComparison"
                    options={BASIS_OF_COMPARISON_ITEMS}
                    label="Basis of Comparison"
                    sx={{ width: '260px' }}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    className={classes.button}
                    color="primary"
                    onClick={handleClickOpen}
                    data-qa="add-category-btn"
                  >
                    ADD CATEGORY
                  </Button>
                  <Button
                    className={classes.button}
                    variant="outlined"
                    color="primary"
                    onClick={addBlankComp}
                    data-qa="comparable-exp-add-blank-btn"
                  >
                    ADD COLUMN
                  </Button>
                </Grid>
              </Grid>
              <Stack direction="column" mb={2}>
                <Typography variant="body1">Include in the report:</Typography>
                <Stack direction="row">
                  <FeatureToggle featureFlag={FEATURE_FLAG_SIMPLIFIED_EXPENSE_COMPS}>
                    <Box display="flex" sx={{ '& .MuiFormControl-root': { flexDirection: 'row' } }}>
                      <CheckboxWithLabel
                        checked={false}
                        handleChange={toggleEGI}
                        label="EGI"
                        name="showEGI"
                        fullWidth={false}
                      />
                    </Box>
                    <Box display="flex" sx={{ '& .MuiFormControl-root': { flexDirection: 'row' } }}>
                      <CheckboxWithLabel
                        checked={false}
                        label="Expense Ratio"
                        name="showExpenseRatio"
                        fullWidth={false}
                      />
                    </Box>
                  </FeatureToggle>
                  <Box display="flex" sx={{ '& .MuiFormControl-root': { flexDirection: 'row' } }}>
                    <CheckboxWithLabel
                      checked={false}
                      handleChange={toggleRealEstateTaxes}
                      label="Real Estate Taxes"
                      name="showRETaxes"
                      fullWidth={false}
                    />
                  </Box>
                </Stack>
              </Stack>
              <FeatureToggle featureFlag={FEATURE_FLAG_AUTO_EXPENSE_COMPS}>
                {comparableExpensesAutomationMetadata && (
                  <AutomationStatus
                    formPaths={['comparableExpensesAutomationMetadata']}
                    message="We automated the information below from Expense Forecast and similar Bowery appraisals"
                    onSourceLinkClick={openSourceLinksModal}
                    sx={{ marginTop: '16px', marginBottom: '16px' }}
                  />
                )}
              </FeatureToggle>
              <ComparableExpensesTable
                propertyType={propertyType}
                rowsApi={rowsApi}
                deleteColumn={deleteColumn}
                basis={basisOfComparison}
                expenseCategories={expenseCategories}
                comparableExpenses={comparableExpenses}
                showExpenseRatio={showExpenseRatio}
                expenseRatio={expenseRatio}
                totalOperatingExpenses={totalOperatingExpenses}
                totalOperatingExpensesPerSF={totalOperatingExpensesPerSF}
                totalOperatingExpensesPerUnit={totalOperatingExpensesPerUnit}
                shouldRefreshTable={shouldRefreshTable}
                deleteRow={removeCategory}
                changeCompModalMode={changeCompModalMode}
                latestCompVersions={latestCompVersions}
                classes={classes}
              />
            </Paper>
            <Paper>
              <RemovedExpenseCompsTable
                removedExpenseComps={removedExpenseComps}
                clearRemovedTable={clearRemovedTable}
                addComp={reAddRemovedComp}
                removeComp={deleteRemovedComp}
                basis={basisOfComparison}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      {isModalOpen && (
        <AddExpenseDialog
          excludedExpenses={excludedExpenses}
          name={EXPENSE_MODAL_NAME}
          onCancel={handleClickClose}
          onSave={addRow}
          validate={validateExpenseName}
        />
      )}
      {comparableExpensesAutomationMetadata && (
        <SourceLinksModal
          open={isSourceLinksModalOpen}
          onClose={closeSourceLinksModal}
          sourceLinks={comparableExpensesAutomationMetadata.sourceLinks}
          generateSourceLink={reportId => `/report/${reportId}/comparable-expenses`}
        />
      )}
    </div>
  )
}

ComparableExpenses.propTypes = {
  classes: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  propertyType: PropTypes.string,
  propertySummary: PropTypes.object.isRequired,
  authenticatedUser: PropTypes.object,
  compPlexLoaded: PropTypes.bool.isRequired,
  reportId: PropTypes.string.isRequired,
  errorNotification: PropTypes.func.isRequired,
  saveReport: PropTypes.func.isRequired,
  expenseAutomationSettings: PropTypes.object,
  isStrictExpensesMode: PropTypes.bool.isRequired,
  yearBuilt: PropTypes.number,
  totalSubjectOperatingExpenses: PropTypes.object,
  totalSubjectOperatingExpensesNetTaxes: PropTypes.object,
}

const formOptions = {
  heading,
  styles,
  mutators: {
    ...arrayMutators,
  },
  subscription: {
    values: true,
    dirty: true,
    hasValidationErrors: true,
  },
  decorators: [computedValueDecorator, columnCalculations, rowCalculations],
  registeredFields: ['comparableExpenses'],
}

const mapStateToProps = state => {
  const expenseHistory = get(state, 'report.reportData.incomeApproach.expenseHistory.expenseHistory', [])
  const projection = expenseHistory.find(expenseItem => expenseItem.isProjected) || {}
  const subjectProjectedExpenses = get(projection, 'expenses', [])
  const organizationId = get(state, 'authentication.user.organizationId')
  const expenseAutomationSettings = get(state, 'report.reportData.organizationSettings.expenseAutomation')
  const isStrictExpensesMode = get(state, 'report.reportData.incomeApproach.expenseHistory.isStrictExpensesMode')
  const yearBuilt = get(state, 'report.reportData.dataCollections.subjectProperty.yearBuilt')
  const residentialUnitCount = get(state, 'report.reportData.propertyInformation.propertySummary.residentialUnitCount')
  const grossBuildingArea = get(state, 'report.reportData.propertyInformation.propertySummary.grossBuildingArea', 0)

  const { formPath } = get(state, 'shared.location.form', {})
  const currentPath = get(state, 'shared.location.form.id')

  return {
    reportId: get(state, 'report.reportData._id'),
    propertyType: get(state, 'report.reportData.propertyType'),
    propertySummary: get(state, 'report.reportData.propertyInformation.propertySummary'),
    totalSubjectOperatingExpenses: get(state, 'report.reportData.incomeApproach.proForma.totalOperatingExpenses'),
    totalSubjectOperatingExpensesNetTaxes: get(
      state,
      'report.reportData.incomeApproach.proForma.totalOperatingExpensesNetTaxes'
    ),
    subjectExpenses: subjectProjectedExpenses.reduce((subjectExpenses, expense) => {
      return { ...subjectExpenses, [expense.id]: { reported: expense.reported, sf: expense.sf, unit: expense.unit } }
    }, {}),
    organizationId,
    authenticatedUser: pick(get(state, 'authentication.user'), ['id', 'username', 'fullName']),
    expenseAutomationSettings,
    isStrictExpensesMode,
    yearBuilt,
    formPath,
    expenseHistory,
    residentialUnitCount,
    grossBuildingArea,
    currentPath,
  }
}

const mapDispatchToProps = dispatch => ({
  errorNotification: error => dispatch(errorNotification({ message: error })),
  saveReport: formPath => dispatch(saveReport(formPath)),
})

export default compose(
  wrapForm(DATA_PATH, formOptions, mapStateToProps, mapDispatchToProps),
  withMicrofrontend('compplex', 'compPlexLoaded')
)(ComparableExpenses)
